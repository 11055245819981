import { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Login } from "../Login/Login";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Loading } from "../../components/Loading/Loading";


import AllActions from "../../store/actions";

import logo from "../../assets/images/ybtlogo.png"; // Tell webpack this JS file uses this image

import { MenuFoldOutlined, MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useTranslate } from "../../components/Translate";
import { PdfViewer } from "../PdfViewer/PdfViewer";

import { ConfigProvider, Dropdown, Menu } from "antd";
import { FactoryMenu, FactorySwitcher } from "../../components/FactorySwitcher/FactorySwitcher";
import { Schedule } from "../Schedule/Schedule";
import { Project } from "./../Project/Project";
import { Projects } from "./../Projects/Projects";

import { LanguageMenu, LanguageSwitcher } from "../../components/LanguageSwitcher/LanguageSwitcher";

import antdLocale from 'antd/locale/fi_FI';

import dayjs from 'dayjs';
import 'dayjs/locale/fi';
import { getEnvSettings } from "../../config/config";
dayjs.locale('fi');

export const Header = () => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1000 });
  const [menuVisible, setMenuVisible] = useState(false);

  const [show, setShow] = useState(true)
  const [y, setY] = useState(window.scrollY)
  const envConfig = getEnvSettings();


  const listenScrollEvent = () => {
    if (window.scrollY <= 30) {
      setShow(true);
    } else if (window.scrollY >= 30) {
      setShow(false);
    }
  };


  useEffect(() => {
    console.log('showAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', show)
  }, [show])


  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    }
  }, []);

  /*   const controlHeader = () => {
      let currY =  window.scrollY
      if (currY < 0) currY = 0
      if (y <= currY) {
        setShow(true)
      } else if (y > currY) {
        setShow(false)
      }
      setY(currY)
    }
  
    useEffect(() => {
      setY(window.scrollY < 0 ? 0 : window.scrollY)
      window.addEventListener('scroll', controlHeader)
      return () => {
        window.removeEventListener('scroll', controlHeader)
      }
    }, [y])
  
  
  
    useEffect(() => {
      console.log('showAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', show)
    }, [show])
   */

  const logOut = () => {
    console.log("LOG_OUT");
    dispatch(AllActions.userActions.logOut());
  };

  const onClick = ({ key }) => {
    console.log(`Click on item ${key}`);

    if (key === "fi") dispatch(AllActions.userActions.updateLang("fi"));
    if (key === "se") dispatch(AllActions.userActions.updateLang("se"));
    if (key === "en") dispatch(AllActions.userActions.updateLang("en"));


    if (key.includes("factory")) {
      const factoryNumber = key.replace("factory_", "");
      dispatch(AllActions.userActions.updateFactory(factoryNumber));
    }


    //updateLang(key);
    //setMenuVisible(false);
  };



  const menu = [
    /*     isTabletOrMobile ?
            {
              label: (
                <Link to={"/schedule"} className="headerbtnlink">
                  <p className="headerprojects font-dm-medium">Aikataulu</p>
                </Link>
              ),
              key: "0",
            }:{}, */
    {
      label: (
        <Link to={"/projects"} className="headerbtnlink">
          <p className="headercontacts font-dm-medium">Projektit</p>
        </Link>
      ),
      key: "projects",
    },
    
    ...FactoryMenu(),

    ...LanguageMenu(),
    {
      type: "divider",
    },

    {
      label: (
        <Link to={""} className="headerbtnlink" onClick={logOut}>
          <p className="headercontacts font-dm-medium">{locale.logout}</p>
        </Link>
      ),
      key: "logout",
    },
  ]

  return (
    <>
      {/*       <p>Y: {y}</p>
 */}
      <div className={show ? "header" : "hiddenHeader"}>

        <div className="headerInside" >
          <img
            className="headerlogo"
            src={logo}
            alt="YBT Oy"
            onClick={() => {
              window.location = "/";
            }}
            href="someLink"
          />
          {/* <LanguageSwitcher additionalClasses={"header-language"} /> */}

          {!isTabletOrMobile ? (
            <div className="header-btns">
              {/* 
      <Link to={"/contacts"} className="headerbtnlink">
        <p className="headercontacts font-dm-medium">
          {locale.contacts}
        </p>
      </Link> */}
              {envConfig.showSchedule ?
                <Link to={"/schedule"} className="headerbtnlink">
                  <p className="headerprojects font-dm-medium">{locale.schedule}</p>
                </Link>
                : <></>}

              <Link to={"/projects"} className="headerbtnlink">
                <p className="headerprojects font-dm-medium">{locale.projects}</p>
              </Link>

              {envConfig.showFactorySel ?
                <div className="headercontacts">
                  <FactorySwitcher additionalClasses={"header-language"} />
                </div>
                : <></>}


              <div className="headercontacts">
                <LanguageSwitcher additionalClasses={"header-language"} />
              </div>

              <Link to={""} className="headerbtnlink" onClick={logOut}>
                <p className="headercontacts logoutbtn font-dm-medium">
                  {locale.logout}
                </p>
              </Link>
            </div>
          ) : (
            <Dropdown

              menu={{ items: menu, onClick }}
              trigger={["click"]}
              open={menuVisible}
              onOpenChange={(visible) => {
                setMenuVisible(visible);
              }}
              placement="bottomRight"
            >
              {menuVisible ? (
                <MenuFoldOutlined className="header-dropdown" />
              ) : (
                <MenuOutlined className="header-dropdown" />
              )}
            </Dropdown>
          )}
        </div>

      </div>
    </>
  );
};

function App() {
  const dispatch = useDispatch();
  const envConfig = getEnvSettings();



  useEffect(() => {
    document.title = `YBT Oy - ${envConfig.title}`; // Quick solution
  }, []);

  /*   const siteUrl = window.location.search;
  const urlParams = new URLSearchParams(siteUrl);
  console.log( 'urlParams',urlParams["auth"], siteUrl) // 159
 */

  useEffect(() => {
    //console.log("LoADUSER");

    const updAuth = async (text) => {
      await localStorage.setItem(
        "USER_AUTH",
        JSON.stringify({
          accessToken: text,
          refreshToken: null,
          authenticated: true,
        })
      );
    };

    const urlParams = new URLSearchParams(window.location.search);
    let authParam = urlParams.get("auth");
    console.log("TESTI5", authParam, urlParams);

    if (authParam) {
      console.log("AUTH LÖYTYY");

      updAuth(authParam);
    }

    dispatch(AllActions.userActions.loadUser());
  }, []);


  const { userLoaded, loggedIn } = useSelector((state) => state.currentUser);

  // console.log("ROOTNAV:", userLoaded, loggedIn);

  if (!userLoaded) {
    return <Loading />;
  }

  if (!loggedIn) {
    return <Login />;
  }

  return (
    <div className="App">
      <ConfigProvider locale={antdLocale}>

        <BrowserRouter>
          <Header />

          <Routes>
            <Route path="/" element={<Projects />} />

            {envConfig.showSchedule && <Route path="/schedule/" element={<Schedule />} />}


            <Route path="/projects/" element={<Projects />} />
            <Route path="/project/:id" element={<Project />} />
            <Route path="/pdfviewer/:id" element={<PdfViewer />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>

    </div>
  );
}

export default App;
