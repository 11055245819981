let config = {
    api_url: "https://webapi.ybt.fi/api", //main
    //api_url: "http://192.168.1.79:3060/api", //dev
    // api_url: "http://192.168.0.10:8080/api", //dev JT

    intra: {
        env: "intra",
        title: "Intra",
        apiUrlPrefix:  "/intra_",
        showSchedule: true,
        showFactorySel: true,
    },
    asiakas: {
        env: "asiakas",
        title: "Portal",
        apiUrlPrefix:  "/asiakas_",
        showSchedule: false,
        showFactorySel: false
    }
};

export const getEnvSettings = () => {
    const environment = process.env.REACT_APP_ENV || 'intra';
    console.log('getEnvSettings',environment)
    return config[environment]
}
  
export default config