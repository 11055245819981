import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import "./AddWorkModal.css";
import { Screen1 } from "./screen1";
import { Screen2 } from './screen2';
import { Screen2Edit } from './screen2edit';
import { Screen3 } from './screen3';
import { Screen4 } from './screen4';
import { Screen3edit } from "./screen3edit";

const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="modalHeaderRow">
        <p className="modalHeader font-dm-bold">{header}</p>
        <CloseOutlined className="modalHeaderClose" onClick={() => closeModal()} />
      </div>
      <p className="modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};

const SaveBtn = ({ onClick, text='Tallenna'  }) => {
  return (
    <div className="saveButtonCont">
      <button className="saveButton font-dm-regular" onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

const DeleteBtn = ({ onClick, text='Poista' }) => {
  return (
    <div className="deleteButtonCont">
      <button className="deleteButton font-dm-regular" onClick={onClick}>
      {text}
      </button>
    </div>
  );
};

export const AddWorkModal = ({ tables, elements,installWorks, data, isOpen, closeModal }) => {
  const [screen, setScreen] = useState(1);
  const [worksData, setWorksData] = useState([]);
  const [selectedWork, setSelectedWork] = useState({});

  console.log("AddWorkModal data", data);

  useEffect(() => {
    console.log("isOpen updated", isOpen);
    
    setScreen(1)

    console.log("modaldata:_", data)

    if (data.poissaoloNro) {
      setScreen(3.1)
    }

    if (data.stockWorker) {
        setScreen(3);
    }
    if (data.editRow) {
      setScreen(2.1);
      setSelectedWork(data.SelectedWork)
    }

    if (data.installWorker) {
      console.log('setScreen4')
      setScreen(4);
    }
  }, [isOpen]);

  useEffect(() => {
    console.log("elements updated", elements);

    /*  if (true) return */

    let newArray = [];
    let userWorksArray = [];

    if (elements) {
      elements.map((row, index) => {
        //console.log(row);
        let arrayId = "id" + row.Tyokohdenro;

        if (!newArray[arrayId]) {
          newArray[arrayId] = {
            id: row.Tyokohdenro,
            name: row.Tyokohde,
            elements: [],
            open: false,
          };
        }

        newArray[arrayId].elements.push(row);
      });

      console.log('AllWorks', newArray);
      setWorksData(newArray);
    }
  }, [elements]);



  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="modalContainer">
          <div className="modal" style={{maxHeight: screen == 2 || screen == 2.1  ? "1000px": "800px" }}>
            {/* Tuotannon kuormitus valinta */}
            {screen == 1 && (
                <Screen1 ModalHeader={ModalHeader} closeModal={closeModal} data={data} worksData={worksData} setWorksData={setWorksData} setSelectedWork={setSelectedWork} setScreen={setScreen} />
            )}

            {/* Tuotannon kuormitus tarkempi */}
            {screen == 2 && (
                <Screen2 ModalHeader={ModalHeader} closeModal={closeModal} tables={tables} data={data} selectedWork={selectedWork} SaveBtn={SaveBtn}/>
            )}
            
            {/* Tuotannon kuormitus muokkaus */}
            {screen == 2.1 && (
                <Screen2Edit ModalHeader={ModalHeader} closeModal={closeModal} tables={tables} data={data} selectedWork={selectedWork} SaveBtn={SaveBtn} DeleteBtn={DeleteBtn} />
            )}

            {/* Poissaolon lisääminen */}
            {screen == 3 && (
                <Screen3 ModalHeader={ModalHeader} closeModal={closeModal} data={data} selectedWork={selectedWork} SaveBtn={SaveBtn}/>
            )}

            {/* Poissaolon Muokkaaminen */}
            {screen == 3.1 && (
                <Screen3edit ModalHeader={ModalHeader} closeModal={closeModal} data={data} selectedWork={selectedWork} SaveBtn={SaveBtn} DeleteBtn={DeleteBtn}/>
            )}

            {/* Asennus tyokohde valinta */}
            {screen == 4 && (
                <Screen4 ModalHeader={ModalHeader} closeModal={closeModal} data={data} installWorks={installWorks} setSelectedWork={setSelectedWork} setScreen={setScreen}  SaveBtn={SaveBtn} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
