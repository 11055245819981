
import { InputNumber, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { apiSecure } from "../../../components/api";
import { getParsedDate } from "../../../components/globalFunctions";
import { Loading } from "../../../components/Loading/Loading";

const { Option } = Select;

export const Screen2 = ({
  ModalHeader,
  closeModal,
  tables,
  data,
  selectedWork,
  SaveBtn,
}) => {
  console.log("screen2 data", data, selectedWork);

  const [isLoading, setisLoading] = useState(false);
  const [selectedLastWork, setSelectedLastWork] = useState(null);
  const [startDate, setStartDate] = useState(moment());

  const [maxPercent, setMaxPercent] = useState(null);
  const [workPercent, setWorkPercent] = useState(null);
  const [workCount, setWorkCount] = useState(null);

  const [workDeciPercent, setWorkDeciPercent] = useState(null)


  const [multiplier, setMultiplier] = useState(1.0);
  const [table, setTable] = useState(null);

  const [calculatedEndDate, setCalculatedEndDate] = useState(moment());




  const saveData1 = () => {
    //if (!setSelectedLastWork) return alert("Valitse edellinen työ");
    if (!startDate) return alert("Aloituspäivämäärä laskennassa virhe");
    if (!workPercent) return alert("Valitse osuus");
    if (!multiplier) return alert("Valitse kerroin");
    if (!table) return alert("Valitse tuotantopaikka");


    if (data.userWorks.length > 0  && !selectedLastWork) return alert("Valitse edellinen työ");

    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `/intra_newWork`,
      data: {
        project: selectedWork.Tyokohdenro,
        elementGroup: selectedWork.ElementtiRyhmaNro,
        earlierWork: selectedLastWork,
        worker: data.userId,
        startDate: startDate,
        workPercent: workPercent / 100,
        multiplier: multiplier,
        table: table,
      },
    };

    console.log(req_config);
    setisLoading(false);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        //setUsersData(response.data.data);
        setisLoading(false);
        closeModal(true)
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };



  
  //Lasketaan maksimiprosentit
  useEffect(() => {
    console.log("SELELELELEL", selectedWork, data)
    if (selectedWork) {
      console.log('selectedWork', selectedWork)

      let KuormittamattaKpl = selectedWork.KuormittamattaKpl
      let ValmistamattaKpl = selectedWork.ValmistamattaKpl

      if (KuormittamattaKpl && ValmistamattaKpl) {
        let percent = (KuormittamattaKpl/ValmistamattaKpl * 100).toFixed(0)
        let percentDeci = (KuormittamattaKpl/ValmistamattaKpl).toFixed(2)

        console.log('percent', percent)
        setMaxPercent(percent)
        setWorkPercent(percent)
        setWorkDeciPercent(percentDeci)
      }
    }
  }, [selectedWork]);

  const calculateDays = (valmistamattaLH) => {
    let hnkKerroin = multiplier
    let kuormitusOsuus = workPercent / 100;

    console.log(
      "Aloitetaan laskentaa 2:",
      kuormitusOsuus,
      hnkKerroin,
      valmistamattaLH
    );

    /*     let remainingDays = (((valmistamattaLH / 8) * kuormitusOsuus) / hnkKerroin +
        (0.05 * valmistamattaLH * kuormitusOsuus) / hnkKerroin) */

    let remainingDays = ((valmistamattaLH / 8) * kuormitusOsuus) / hnkKerroin


    console.log("Laskennan tulos2:", remainingDays);
    return remainingDays
  }

  useEffect(() => {
    if (selectedWork) {
      let ValmistamattaKpl = selectedWork.ValmistamattaKpl;

      if (ValmistamattaKpl) {
        let count = ((workPercent / 100) * ValmistamattaKpl).toFixed(2); //  (KuormittamattaKpl/ValmistamattaKpl * 100).toFixed(0)
        console.log("count", workPercent, ValmistamattaKpl, count);
        //setMaxPercent(percent)
        setWorkCount(count);
      }
    }

    let valmistamattaLH = selectedWork.ValmistamattaLaskennallisestiH;

    if (workPercent && multiplier && valmistamattaLH && startDate) {
      let dayss = calculateDays(valmistamattaLH)


      //jaetaan työpäivien määrällä
      dayss = dayss / 5

      //Kerrotaan viikolla, saadaan oikea määrä "työpäiviä"
      dayss = dayss * 7

      let calc = moment(startDate).add(dayss, "days");
      setCalculatedEndDate(calc);
    }
  }, [workPercent, multiplier, startDate]);




  const calcDaily = (percent, target, days) => {
    // console.log("daily:_", percent, target, days)

    let dailyAmount = target / days
    let result = (percent * dailyAmount).toFixed(1)

    // console.log("daily2", result)

    return result

  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ModalHeader
        closeModal={() => closeModal()}
        header={"Lisää tuotanto"}
        user={data.userName}
      />

<div className="screen2Cont">
          <div>
            <p className="header1 font-dm-bold">{selectedWork.Tyokohde}</p>
            <div className="tyokohdeRow">

              <p className="header2 font-dm-medium">
                {selectedWork.ElementtiRyhma +
                  " " +
                    (selectedWork.ValmistamattaKpl
                      ? selectedWork.ValmistamattaKpl
                      : "0") +
                    " kpl, "+(selectedWork.KuormittamattaKpl
                      ? selectedWork.KuormittamattaKpl
                      : "0") +
                    " kpl kuormittamatta"
                    }
              </p>
            </div>
          </div>

        {data.jobEnding && (
          <p
            className="font-dm-bold"
            style={{
              color: "red",
            }}
          >
            Huom. Työsuhde loppuu: {getParsedDate(data.jobEnding)}{" "}
          </p>
        )}

        <div className="screen2Prod">
          <p className="header1 font-dm-bold">Tuotanto</p>

          <ul className="events">
            <li>
              <ball />
              <div className="item line">
                <p className="font-dm-medium">Edellinen työ</p>
                <div className="item-right">
                  {/* data.userWorks */}

                  <Select
                    defaultValue="0"
                    style={{ width: 300}}
                    className="picker value"
                    onChange={(value, option) => {
                      let selected = data.userWorks[value - 1];
                      console.log("selected", selected);
                      if (selected) {
                        console.log("update selected");
                        setSelectedLastWork(selected.KirjausId);
                        let startdate = moment(selected.endDate).add(
                          1,
                          "day"
                        );
                        setStartDate(startdate);
                      } else {
                        setSelectedLastWork(null)
                        //setStartDate(null);
                        setStartDate(moment());
                      }
                    }}
                  >

                    <Option value="0">Valitse...</Option> 



                    {data.userWorks.map((row, index) => {

                      return (
                        <Option key={index + 1} value={index + 1}>
                          {row.name + ' | '+ row.elementgroup}
                        </Option>
                      );
                    })}
                  </Select>

                  {/*   <p className="font-dm-medium">Kemi metsä fibre</p> */}
                </div>
              </div>
            </li>

            <li>
              <ball />
              <div className="item line">
                <p className="font-dm-medium">Arvioitu aloituspäivämäärä</p>
                <div className="item-right">
                  {/* <p className="font-dm-medium">20.9.2022</p> */}
                  <p className="font-dm-medium">
                    {startDate ? getParsedDate(startDate) : "Ei valittu"}
                  </p>
                </div>
              </div>
            </li>

            <li>
              <ball />
              <div className="item line">
                <p className="font-dm-medium">Osuus valmistuksesta ({workCount} kpl)</p>
                <div className="item-right">
                  {/* <p className="font-dm-medium">100%</p> */}
                  {maxPercent && 
                  <InputNumber
                    style={{ width: 70 }}
                    defaultValue={maxPercent}
                    min={0}
                    max={maxPercent}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    onChange={(value) => {
                      console.log("change value", value);
                      setWorkPercent(value);
                      setWorkDeciPercent((value / 100).toFixed(2))
                    }}
                  />
                }
                </div>
              </div>
            </li>

            <li>
              <ball />
              <div className="item line">
                <p className="font-dm-medium">Henkilökohtainen kerroin</p>
                <div className="item-right">
                  {/* <p className="font-dm-medium">1,0</p> */}
                  <InputNumber
                    style={{ width: 70 }}
                    defaultValue={1.0}
                    min={0.5}
                    max={5.0}
                    step={0.1}
                    onChange={(value) => {
                      console.log("change value", value);
                      setMultiplier(value);
                    }}
                  />
                </div>
              </div>
            </li>

            <li>
              <ball />
              <div className="item line">
                <p className="font-dm-medium">Tuotantopaikka</p>
                <div className="item-right">
                  <Select
                    defaultValue="0"
                    style={{ width: 150 }}
                    className="picker value"
                    onChange={(value, option) => {
                      let selected = tables[value - 1];
                      console.log("selected", selected);

                      if (selected) {
                        setTable(selected.PoytaNro);
                      } else {
                        setTable(null);
                      }
                    }}
                  >
                    <Option value="0">Valitse...</Option>
                    {tables.map((row, index) => {
                      /*                       console.log('tables', index+1, row)
                       */
                      return (
                        <Option key={index + 1} value={index + 1}>
                          {row.Poyta}
                        </Option>
                      );
                    })}
                  </Select>

                  {/*  <p className="font-dm-medium">1. Elementtipöytä</p> */}
                </div>
              </div>
            </li>

            <li>
              <ball className="last" />
              <div className="item">
                <p className="font-dm-medium">
                  {  "Arvioitu valmistuminen "+ getParsedDate(calculatedEndDate) }

                  {/* laskenta: dbo.AikajanaTulevaTuotanto.ValmistamattaLaskennallisestiH / 8 * dbo.AikajanaMenkinta.KuormitusOsuus / dbo.AikajanaMenkinta.HenkilokohtainenKerroin + 0,05 * dbo.AikajanaTulevaTuotanto.ValmistamattaLaskennallisestiH * dbo.AikajanaMenkinta.KuormitusOsuus / dbo.AikajanaMenkinta.HenkilokohtainenKerroin */}
                </p>
              </div>
            </li>
            <div className="kestoDiv">
                <p className="font-dm-medium">
                  Tuotannon kesto {calculateDays(selectedWork.ValmistamattaLaskennallisestiH).toFixed(2)} työpäivää
                </p>
              </div>
          </ul>


          
          <div className="tyoaikaContainer">
              <p className="tyoaikaHeader">Työaika</p>

              <div className="tyoaikaRow">
                <p className="tyoaikaTitle">Tarjouslaskenta</p>
                <p className="tyoaikaText">
                {selectedWork.LaskennanTyoaikaYksikolle} <span className="tyoaikaSmallText">h/{!selectedWork.jmTuote ? "m2" : "jm"}</span>  
                </p>
              </div>

              <div className="tyoaikaRow">
                <p className="tyoaikaTitle">Kuormituksessa</p>
                <p className="tyoaikaText">
                      {selectedWork.LaskennanTyoaikaYksikolle ? (selectedWork.LaskennanTyoaikaYksikolle / multiplier).toFixed(1) : '-'} <span className="tyoaikaSmallText">h/{!selectedWork.jmTuote ? "m2" : "jm"}</span>  
                </p>
              </div>
            </div>

          <div className="yhteenvetoContainer">

            <p className="yhteenvetoHeader">Valmistuksen yhteenveto</p>

            <div className="yhteenvetoRow">
              <p className="yhteenvetoTitle">Päivässä</p>
              <p className="yhteenvetoText">
                 {calcDaily(workDeciPercent, selectedWork.ValmistamattaMaara, calculateDays(selectedWork.ValmistamattaLaskennallisestiH))}
                 <span className="yhteenvetoSmallText">{selectedWork.jmTuote ? "jm" : "m2"}</span> 
              </p>

              <p className="yhteenvetoText">
                {calcDaily(workDeciPercent, selectedWork.ValmistamattaKpl, calculateDays(selectedWork.ValmistamattaLaskennallisestiH))}
                 <span className="yhteenvetoSmallText">kpl</span> 
              </p>

              {selectedWork.ArvioTuotantopalkkioYht != 0 && (
                <p className="yhteenvetoText">
                  {(workDeciPercent * (selectedWork.ArvioTuotantopalkkioYht / calculateDays(selectedWork.ValmistamattaLaskennallisestiH))).toFixed(2)}
                  <span className="yhteenvetoSmallText">€</span> 
                </p>
              )}

              
            </div>

            <div className="yhteenvetoRow">
              <p className="yhteenvetoTitle">Yhteensä</p>
              <p className="yhteenvetoText">
                 {(workDeciPercent * selectedWork.ValmistamattaMaara).toFixed(1)}
                 <span className="yhteenvetoSmallText">{selectedWork.jmTuote ? "jm" : "m2"}</span> 
              </p>

              <p className="yhteenvetoText">
                 {(workDeciPercent * selectedWork.ValmistamattaKpl).toFixed(1)}
                 <span className="yhteenvetoSmallText">kpl</span> 
              </p>

              {selectedWork.ArvioTuotantopalkkioYht != 0 && (
                <p className="yhteenvetoText">
                  {(workDeciPercent * selectedWork.ArvioTuotantopalkkioYht).toFixed(2)}
                  <span className="yhteenvetoSmallText">€</span> 
                </p>

              )}

            </div>

          </div>


        </div>

        <SaveBtn onClick={saveData1} />
      </div>
    </>
  );
};
