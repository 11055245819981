import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";

export const Screen1 = ({ModalHeader,closeModal, data, worksData,setWorksData, setSelectedWork, setScreen}) => {

  





    return (
        <>
        <ModalHeader
        closeModal={() => closeModal()}
        header={"Lisää tuotanto"}
        user={data.userName}
      />

      <div className="worksData">
        {Object.keys(worksData).map((key) => {
          let item = worksData[key];

          //console.log('worksData row', item)

          let foundSom = item.elements.find((obj) => obj.KuormittamattaKpl >= 1)
          console.log('worksData foundSom', foundSom)
          if (!foundSom) return  
 
          return (
            <div key={key}>
              <div className="screen1WorkCont"
              
              onClick={() => {
                item.open = !item.open;
                setWorksData({ ...worksData });
              }}
              >
                <p
                  className="name font-dm-bold"
    
                >
                  {item.name}
                </p>

                {item.open ? (
                  <UpOutlined className="arrowPlus" />
                ) : (
                  <DownOutlined className="arrowPlus" />
                )}
              </div>

              {item.open &&
                item.elements.map((item2, index) => {


                  if (!item2.KuormittamattaKpl >= 1) return

  

                  return (
                    <p
                      key={index}
                      className="elementName font-dm-medium"
                      onClick={() => {
                        console.log("setSelectedWork", item2);
                        setSelectedWork(item2);
                        setScreen(2);
                      }}
                    >
                      {item2.ElementtiRyhma + " " + (item2.KuormittamattaKpl ? item2.KuormittamattaKpl : item2.KuormittamattaKpl) + " kpl "}{" "}
                      <a style={{ color: "#CECECE" }}>
                        {"/ " + (item2.ValmistamattaKpl ? item2.ValmistamattaKpl : '0') + " kpl"}
                      </a>
                    </p>
                  );
                })}
            </div>
          );
        })}
      </div>

{/*       <div className="modalFooter">
        <p
          onClick={() => {
            setScreen(3);
          }}
        >
          Lisää poissaolo
        </p>
      </div> */}

      <div className="footerButtonCont">
      <button className="footerButton font-dm-regular" onClick={() => setScreen(3)}>
        Lisää poissaolo
      </button>
    </div>


      </>
    )
}