import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App/App';
import reportWebVitals from './reportWebVitals';


import { Provider } from 'react-redux'
import store from './store'

import './assets/fonts/DMSans-Bold.ttf';
import './assets/fonts/DMSans-Medium.ttf';
import './assets/fonts/DMSans-Regular.ttf';
import './assets/fonts/ITCAvantGardeStd-Bold.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

 {/*  <React.StrictMode> */}
 {/*  </React.StrictMode> */}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
