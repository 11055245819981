import "./ProgressBar.css";

function isWhatPercentOf(numA, numB) {
  return (numA / numB) * 100;
}

export function ProgressBar({
  data1 = 0,
  data2 = 0,
  backgroundColor = "#14DC40",
  style = {},
}) {
  if (!data1) data1 = 0;
  if (!data2) data2 = 0;

  let percent = isWhatPercentOf(data2, data1).toFixed(2);

  //console.log("percent", percent, data1, data2);

  //percent = percent+'%'
  //console.log("Progressbar", data1, data2, percent);

  return (
    <>
      <div style={style}>
        <div className="pbbase">
          {percent >= 0.1 && (
            <div
              className="pb"
              style={{ backgroundColor: backgroundColor, width: percent + "%" }}
            >
              <span className="pbcurrenttext font-dm-bold">{data2}</span>
            </div>
          )}

          {data2 == 0 && (
            <span className="pbmintext font-dm-bold">{data2}</span>
          )}


          {!(percent >= 100) ? 
          <span className="pbmaxtext font-dm-bold">{data1}</span>
          : null}

        </div>
      </div>
    </>
  );
}

export function ProgressBarMulti({
  data1 = 0,
  data2 = 0,
  data3 = 0,
  backgroundColor = "#14DC40",
  style = {},
}) {
  if (!data1) data1 = 0;
  if (!data2) data2 = 0;

  let data2percent = isWhatPercentOf(data2, data1).toFixed(2);
  let data3percent = isWhatPercentOf(data3, data1).toFixed(2);

  //console.log("percent", percent, data1, data2);

  //percent = percent+'%'
  //console.log("Progressbar", data1, data2, percent);

  return (
    <>
      <div style={style}>
        <div className="pbbaseMulti">
          {data2percent >= 0.1 && (
            <div
              className="pbMulti"
              style={{ backgroundColor: backgroundColor, width: data2percent + "%" }}
            >
              <span className="pbcurrenttext font-dm-bold">{data2}</span>
            </div>
          )}

          {data3percent >= 0.1 && (
            <div
              className="pbMulti"
              style={{ backgroundColor: '#2B4BF2', width: data3percent + "%" }}
            >
              <span className="pbcurrenttext font-dm-bold">{data3}</span>
            </div>
          )}

          {data2 == 0 && (
            <span className="pbmintext font-dm-bold">{data2}</span>
          )}



{!(data2percent >= 100) && !(data3percent >= 100)   ? 
          <span className="pbmaxtext font-dm-bold">{data1}</span>
          : null}

        </div>
      </div>
    </>
  );
}

/*

    <View style={style}>
      <View style={styles.pbbase}>

        <View style={[styles.pb, {backgroundColor: backgroundColor},
          !isNaN(percent) ? {width:percent+'%'} : null
        ]}>
          <Text style={styles.pbcurrenttext}>{data2}</Text>
        </View>

        {data2 == 0 ?
              <Text style={[styles.pbmintext]}>{data2}</Text>
        : null}

        <Text style={[styles.pbmaxtext]}>{data1}</Text>
      </View>
    </View>

    */
