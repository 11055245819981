import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import { DatePicker, Input } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../components/Translate";

const { RangePicker } = DatePicker;

//import "./AddWorkModal.css";

const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="proj-modalHeaderRow">
        <p className="proj-modalHeader font-dm-bold">{header}</p>
        <CloseOutlined
          className="proj-modalHeaderClose"
          onClick={() => closeModal()}
        />
      </div>
      <p className="proj-modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};

const SaveBtn = ({ onClick }) => {
  const { locale } = useTranslate();

  return (
    <div className="proj-saveButtonCont">
      <button className="proj-saveButton font-dm-regular" onClick={onClick}>
        {locale.save}
      </button>
    </div>
  );
};

export const EditStageData = ({ isOpen, closeModal, data, saveFunc, deleteStage, setVerifyDeleteData, setVerifyDeleteOpen }) => {
  console.log("SelectStageData data", data);

  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { locale } = useTranslate();

  useEffect(() => {
    console.log("isOpen updated", isOpen);

    if (isOpen) {

      let newName = data.name
      let startdate = data.start && dayjs(data.start)
      let enddate = data.end && dayjs(data.end)

      console.log("Name", newName)
      console.log('startDate', startdate)
      console.log('enddate', enddate)

      if (newName) setName(newName)
      if (startdate) setStartDate(startdate)
      if (enddate) setEndDate(enddate)



    } else {
      setName(null)
      setStartDate(null)
      setEndDate(null)
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="proj-modalContainer">
          <div className="proj-modal">
            <ModalHeader
              closeModal={() => closeModal()}
              header={locale.etapedit}
            // user={"Muokkaa etapin tietoja"}
            />

            <p className="font-dm-medium label">{locale.etapeditname}</p>

            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                console.log("onChange", e.target.value)
              }}
            />

            <p className="font-dm-medium label">{locale.etapeditdate}</p>

            <RangePicker
              className="rangepicker"
              size="small"
              showWeek={true}
              suffixIcon={null}
              value={[startDate, endDate]}
              format={'DD.MM.YYYY'}
              onChange={(value) => {
                //console.log("onChange", value, dayjs(value[0]).format("YYYY-MM-DD"), dayjs(value[1]).format("YYYY-MM-DD"));
                setStartDate(value[0]);
                setEndDate(value[1]);
              }}
              onOk={() => {
                console.log("onOk");
              }}
            />



            <div className="proj-cancelButtonCont">
              <button className="proj-cancelButton font-dm-regular" onClick={() => {
                closeModal()


/* 
                let itemId = parseInt(
                  list.replace("LIST_", "")
                );
    */
                setVerifyDeleteData({
                  name: data.name,
                  parent: data.parent,
                  verifyFunc: () => {
                    deleteStage(data.id);
                  },
                });
                setVerifyDeleteOpen(true); 
              }}>
                {locale.etapeditremove}
              </button>
            </div>

            <SaveBtn onClick={() => {
              closeModal()
              saveFunc(data.id, name, startDate ? dayjs(startDate).format("YYYY-MM-DD") : null, endDate ? dayjs(endDate).format("YYYY-MM-DD") : null)
            }
            } />
          </div>
        </div>
      </Modal>
    </>
  );
};
