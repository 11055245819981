import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import {
  FilePdfOutlined,
  MenuOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  NumberOutlined
} from "@ant-design/icons";
import Icon from '@mdi/react';
import { mdiCheckboxMarkedOutline, mdiCropSquare, mdiPlayBoxOutline, mdiTruckDeliveryOutline } from '@mdi/js';

import { DatePicker, Dropdown, Menu } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getParsedDate, getParsedTime, getParsedTimeNoZero } from "../../components/globalFunctions";
import { PdfButton } from "../../components/PdfButton/PdfButton";
import {
  ProgressBar,
  ProgressBarMulti
} from "../../components/ProgressBar/ProgressBar";
import { useTranslate } from "../../components/Translate";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import moment, { isMoment } from "moment";


import { getPdf } from "./../../components/PdfButton/PdfButton";
import { NewStage } from "./NewStage";
import { copy, DraggableRowItem, move, RenderListFooter, RenderListHeader, reorder } from "./ProjectDraggable";
import { EditStageData } from "./EditStageData";
import { VerifyDelete } from "./VerifyDelete";
import { Header } from './../App/App';
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";



export const Project = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const { locale } = useTranslate();
  let { id } = useParams();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isMediumWidth = useMediaQuery({ maxWidth: 1100 });

  const isFirstRender = useRef(true);


  const [pdfLoading, setPdfLoading] = useState(false);


  const [elementListOpen, setElementListOpen] = useState({});
  const [etappiListOpen, setEtappiListOpen] = useState({});
  const [aikatauluListOpen, setAikatauluListOpen] = useState({});

  const [animation, setAnimation] = useState("");
  const [useDragging, setUseDragging] = useState(false);

  const [enableEditing, setEnableEditing] = useState(false);

  const [copiedList, setCopiedList] = useState({});
  const [droppableLists, setDroppableLists] = useState({});

  const { tehtaat, lang, selectedFactory  } = useSelector((state) => state.currentUser.user);


  const [listType, setListType] = useState("elementit");

  const [itemsInDroppable, setItemsInDroppable] = useState({});

  const [stageDataOpen, setStageDataOpen] = useState(false);
  const [stageData, setStageData] = useState({});

  const [newStageOpen, setNewStageOpen] = useState(false);

  const [verifyDeleteOpen, setVerifyDeleteOpen] = useState(false);
  const [verifyDeleteData, setVerifyDeleteData] = useState({});

  const [noEtappiTotal, setNoEtappiTotal] = useState({});
  const [etappiTotal, setEtappiTotal] = useState([]);
  const [parentEtappiTotal, setParentEtappiTotal] = useState([]);

  const [menuVisible, setMenuVisible] = useState(false);


  const [tableFilter, setTableFilter] = useState("tunnus");
  const [sortDirection, setSortDirection] = useState("asc");



  const [aikatauluData, setAikatauluData] = useState([]);
  const [noAikatauluData, setNoAikatauluData] = useState([]);




  //Tehdasmuutos
  useEffect(() => {
    if (isFirstRender.current) {
      // Ensimmäinen renderöinti, ei suoriteta funktiota
      isFirstRender.current = false;
      return;
    }

    console.log('selectedFactory change', selectedFactory)
    navigate("/projects/")
  }, [selectedFactory]); // Lisää selectedFactory riippuvuuslistaan




  //////
  //////  DRAGGING START
  //////




  /*   useEffect(() => {
      console.log("COPIEDLIST Update", copiedList);
    }, [copiedList]); */

  useEffect(() => {
    if (Object.keys(droppableLists).length <= 0) return;

    /*     console.log(
          "DroppableUpdate",
          Object.keys(droppableLists).length,
          droppableLists
        ); */
    let newArray = {};

    Object.keys(droppableLists).map((list, index) => {
      droppableLists[list].items.map((item, index2) => {
        console.log(item);

        let itemId = parseInt(item.id.replace("_dropped", ""));

        newArray[itemId] = true;
      });
    });

/*     console.log("itemsInDroppable", newArray);
 */    setItemsInDroppable(newArray);
  }, [droppableLists]);

  useEffect(() => {
    //console.log("CREATE ETAPIT");
    //console.log(projectData.etapit); 

    if (projectData.etapit) {
      //console.log("CREATE ETAPIT2");

      let newData = {};

      projectData.etapit.map((item, index) => {
        let isParent = item.ParentID === 0 ? true : false;

        newData["LIST_" + item.EtappiNro] = {
          title: item.Etappi,
          parentlist: isParent ? item.EtappiNro : item.ParentID,
          items: [],
          parent: isParent,
          startdate: item.ArvioituToimituksenAlku,
          enddate: item.ArvioituToimituksenLoppu,
        };

        /*         if (item.ParentID === 0) {
          newData["LIST_" + item.EtappiNro] = {
            title: item.Etappi,
            parentlist: item.EtappiNro,
            items: [],
            parent: true,
            startdate: item.ArvioituToimituksenAlku,
            enddate: item.ArvioituToimituksenLoppu,
          };
        } else {
          newData["LIST_" + item.EtappiNro] = {
            title: item.Etappi,
            parentlist: item.ParentID,
            items: [],
            startdate: item.ArvioituToimituksenAlku,
            enddate: item.ArvioituToimituksenLoppu,
          };
        } */
      });

      //console.log("creategroups", projectData.elementtiryhmat);

      projectData.elementtiryhmat.map((group, index) => {
        group.data &&
          group.data.map((item, index2) => {
            if (!item.Etappi) return;

            //console.log("FOUND ETAPPI", item);

            if (newData["LIST_" + item.Etappi]) {
              newData["LIST_" + item.Etappi].items.push({
                id: item.ValmistusNro + "_dropped",
                date: item.ArvioituToimitusPvm,
                order: item.JarjestysEtapissa,
              });
            }
          });
      });

      //LAitetaan etapit järjestykseen
      Object.keys(newData).map((list, index) => {
        let items = newData[list].items;

        items.sort(function (a, b) {
          return a.order - b.order;
        });
      });

      //console.log("NEW DATA", newData);

      setDroppableLists(newData);
    }
  }, [projectData]);


  // Lasketaan etappien elementtien yhteisarvot
  useEffect(() => {
    let tmp = {
      total: 0,
      m2: 0,
      paino: 0,
      piirustus: 0,
      valmistus: 0,
      toimitus: 0,
    }

    let noEtapValues = JSON.parse(JSON.stringify(tmp))
    let EtapValueArray = []

    projectData?.elementit?.map((itm, idx) => {
      if (itm.Etappi) {
        if (!EtapValueArray[itm.Etappi]) {
          EtapValueArray[itm.Etappi] = JSON.parse(JSON.stringify(tmp))
        }
        EtapValueArray[itm.Etappi].total += 1
        EtapValueArray[itm.Etappi].m2 += itm.M2
        EtapValueArray[itm.Etappi].paino += itm.Paino
        EtapValueArray[itm.Etappi].piirustus += itm.Piirustus ? 1 : 0
        EtapValueArray[itm.Etappi].valmistus += itm.ValmistusPVM ? 1 : 0
        EtapValueArray[itm.Etappi].toimitus += itm.ToimitusPVM ? 1 : 0
      } else {
        noEtapValues.total += 1
        noEtapValues.m2 += itm.M2
        noEtapValues.paino += itm.Paino
        noEtapValues.piirustus += itm.Piirustus ? 1 : 0
        noEtapValues.valmistus += itm.ValmistusPVM ? 1 : 0
        noEtapValues.toimitus += itm.ToimitusPVM ? 1 : 0

      }
    })



    //Lasketaan juurietapin arvot
    let parentEtapTotal = []
    projectData?.elementit?.map((itm, idx) => {
      if (itm.Etappi) {

        let etapId

        let etapData = projectData.etapit.find(etap => etap.EtappiNro === itm.Etappi)

        if (etapData?.EtappiNro) etapId = etapData?.EtappiNro
        if (etapData?.ParentID) etapId = etapData?.ParentID

        if (!etapId) return

        if (!parentEtapTotal[etapId]) {
          parentEtapTotal[etapId] = JSON.parse(JSON.stringify(tmp))
        }

        parentEtapTotal[etapId].total += 1
        parentEtapTotal[etapId].m2 += itm.M2
        parentEtapTotal[etapId].paino += itm.Paino
        parentEtapTotal[etapId].piirustus += itm.Piirustus ? 1 : 0
        parentEtapTotal[etapId].valmistus += itm.ValmistusPVM ? 1 : 0
        parentEtapTotal[etapId].toimitus += itm.ToimitusPVM ? 1 : 0
      }
    })



    setNoEtappiTotal(noEtapValues)
    setEtappiTotal(EtapValueArray)

    setParentEtappiTotal(parentEtapTotal)
  }, [projectData]);




  
  // Lasketaan aikataulu elementtien yhteisarvot
  useEffect(() => {
    let tmp = {
      total: 0,
      m2: 0,
      paino: 0,
      piirustus: 0,
      valmistus: 0,
      toimitus: 0,
      elements: []
    }

    let noAikatauluArr = JSON.parse(JSON.stringify(tmp))
    let aikatauluArr = {}

     projectData?.elementit?.map((itm, idx) => {
      if (itm.ArvioituToimitusPvm) {
        let ndate = getParsedDate(itm.ArvioituToimitusPvm)

        if (!aikatauluArr[ndate]) {
          aikatauluArr[ndate] = JSON.parse(JSON.stringify(tmp))
        }

        aikatauluArr[ndate].total += 1
        aikatauluArr[ndate].m2 += itm.M2
        aikatauluArr[ndate].paino += itm.Paino
        aikatauluArr[ndate].piirustus += itm.Piirustus ? 1 : 0
        aikatauluArr[ndate].valmistus += itm.ValmistusPVM ? 1 : 0
        aikatauluArr[ndate].toimitus += itm.ToimitusPVM ? 1 : 0

        aikatauluArr[ndate].elements.push(itm)
      } else {
        noAikatauluArr.total += 1
        noAikatauluArr.m2 += itm.M2
        noAikatauluArr.paino += itm.Paino
        noAikatauluArr.piirustus += itm.Piirustus ? 1 : 0
        noAikatauluArr.valmistus += itm.ValmistusPVM ? 1 : 0
        noAikatauluArr.toimitus += itm.ToimitusPVM ? 1 : 0
        noAikatauluArr.elements.push(itm)
      }

    })


    console.log('test4 aikatauluArr, ',aikatauluArr)
    console.log('test4 noAikatauluArr, ',noAikatauluArr)

    setAikatauluData(aikatauluArr)
    setNoAikatauluData(noAikatauluArr)
  }, [projectData]);







  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    //console.log("onDragEnd ==> result", result);

    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (true) {
      case source.droppableId === destination.droppableId: //Sijainnin vaihto listassa
        //console.log("CASE 1");
        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: reorder(
              droppableLists[source.droppableId].items,
              source.index,
              destination.index
            ),
          },
        });
        break;

      case source.droppableId.includes("GROUP"): //Listasta tauluun siirto
        //console.log("CASE 2");

        let isInArray = isDraggableInAnyList(result.draggableId);

        //console.log("isInArray", isInArray);

        if (isInArray) break;

        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              null,
              draggableId + "_dropped",
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });
        break;

      case source.droppableId.includes("LIST"): //Taulusta toiseen siirto
        //console.log("CASE 3");

        setDroppableLists({
          ...returnRemoveDraggableArray(source.droppableId, draggableId),
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              source.droppableId,
              draggableId,
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });

        break;

      default: //Jos muuta ei ole
        //console.log("CASE 4");

        if (source.droppableId.includes("GROUP")) return;
        return;
        setDroppableLists(
          move(
            droppableLists[source.droppableId],
            droppableLists[destination.droppableId],
            source,
            destination
          )
        );
        break;
    }
  };

  const isDraggableInAnyList = (draggable_id) => {
    //console.log("isDraggableInAnyList");

    let inArray = false;

    Object.keys(droppableLists).map((list, index) => {
      let found = droppableLists[list].items.some(
        (x) => x.id === draggable_id + "_dropped"
      );
      if (found) inArray = true;
    });

    return inArray;
  };

  const returnRemoveDraggableArray = (list_id, draggable_id) => {
    //console.log("removeDraggable list", droppableLists);
    let newArray = droppableLists[list_id].items.filter(
      (item) => item.id != draggable_id
    );
    //console.log("removeDraggable newArray", newArray);

    return {
      ...droppableLists,
      [list_id]: {
        ...droppableLists[list_id],
        items: newArray,
      },
    };
  };

  const removeDraggable = (list_id, draggable_id) => {
    setDroppableLists(returnRemoveDraggableArray(list_id, draggable_id));
  };






  const onBeforeCapture = (result) => {
    console.log("onBeforeCapture");
    //console.log("==> beforeresult", result);


    const id = result.draggableId;

    if (id.includes("dropped")) return;

    const cardSize = 140;
    const draggableAttr = "data-rbd-drag-handle-draggable-id";
    const getAttr = (key, value) => `[${key}="${value}"]`;
    const draggableQuery = getAttr(draggableAttr, id);
    //console.log("draggableQuery:", draggableQuery);
    const draggable = document.querySelector(draggableQuery);
    // console.log("DRAGGABLE:", draggable);

    //console.log('coords22', draggable.getBoundingClientRect())
    draggable.setAttribute("style", `width: ${cardSize}px;`);
  };

  const getRowData = (id) => {
    //console.log('getRowData')

    if (!projectData.elementtiryhmat) return;

    let data = {};

    projectData.elementtiryhmat.map((group, index) => {
      //console.log('group', group)

      if (!group.data) return "asd";

      let foundItem = group.data.filter(
        (element) => element.ValmistusNro === id
      );

      if (foundItem.length >= 1) {
        //console.log('Setting data')
        data = foundItem[0];
      }
      //console.log('foundItem', foundItem)
    });

    //console.log('returning ', data)
    return data;
  };

  const handleShowDragging = async () => {
    if (useDragging) {
        setAnimation("closing");
        await new Promise((r) => setTimeout(r, 500)); // 0.5s delay
        setAnimation("closed");
    } else {
        setAnimation("open");
    }
    setUseDragging(!useDragging);
};

  //////
  //////  DRAGGING END
  //////

  const getProject = () => {
    setisLoading(true);
    console.log("getProject");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProject`,
      data: {
        projectId: id,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //console.log("RESPONSE", response.data);
        setProjectData(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  const updateStages = () => {
    // console.log("updateStages start", droppableLists);

    let newList = [];

    Object.keys(droppableLists).map((list, index) => {
      let listId = parseInt(list.replace("LIST_", ""));

      droppableLists[list].items.length >= 1 &&
        droppableLists[list].items.map((item, index2) => {
          console.log("ITEM", item);
          let itemId = parseInt(item.id.replace("_dropped", ""));
          newList.push({
            elementid: itemId,
            stage: listId,
            date: item.date,
            order: index2 + 1,
          });
        });
    });

    // console.log("updateStages send LIST", newList);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}`,
      data: {
        projectId: id,
        list: newList,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        // console.log("RESPONSE", response.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const addNewStage = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}newStage`,
      data: {
        projectId: id,
        stage: stage,
        name: name,
        start: start,
        end: end,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //   console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const deleteStage = (stage) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteStage`,
      data: {
        projectId: id,
        stage: stage,
      },
    };

    //console.log("deletestage", req_config);

    apiSecure(req_config)
      .then((response) => {
        //  console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateStageDatas = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateStageDatas`,
      data: {
        projectId: id,
        stage: stage,
        name: name,
        start: start,
        end: end,
      },
    };
    // console.log(req_config);

    apiSecure(req_config)
      .then((response) => {
        //      console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    getProject();
    //console.log("useEffect called");
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!projectData.tiedot) {
    return (
      <>
        <p>Ei tietoa</p>
        <button onClick={getProject}>{"HAE PROJEKTI"}</button>
        <button onClick={() => navigate(-1)}>Go back</button>
      </>
    );
  }

  const handleOpenElement = (id) => {
    let OpenList = elementListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setElementListOpen({
      ...OpenList,
    });
  };

  const handleOpenEtappi = (id) => {
    let OpenList = etappiListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setEtappiListOpen({
      ...OpenList,
    });
  };

  const handleOpenAikataulu = (id) => {
    let OpenList = aikatauluListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setAikatauluListOpen({
      ...OpenList,
    });
  };

  const handleOpenEtappiData = (id, name, start, end, parent) => {
    if (enableEditing) return;

    console.log("handleOpenEtappiDate", id);

    setStageData({
      id,
      name,
      start,
      end,
      parent
    });
    setStageDataOpen(true);
  };

  const updateDates = (list, date) => {
    let preArray = droppableLists[list];
    //console.log("updateDates", list);
    // console.log("preList", preArray);

    preArray.items.map((item, index) => {
      //console.log("Changing date", item.id);
      //console.log("OLD", item.date);
      //console.log("NEW", date);
      item.date = date;
    });

    console.log("afterLis2t", preArray);

    setDroppableLists({
      ...droppableLists,
      [list]: preArray,
    });
  };


  //Tarkistetaan onko juurietapilla alaetappeja
  const checkParentStageItems = (stage) => {
    // console.log('checkParentStageItems')

    let count = 0

    {
      Object.keys(droppableLists).map((list, i) => {
        let listdata = droppableLists[list]

        if (listdata.parentlist == stage && !listdata.parent) count = count + listdata.items.length
      })

      return count
    };

  }

  /* 
  
  ArvioituToimitusPvm
  : 
  null
  ElementtiNro
  : 
  45672
  Etappi
  : 
  215
  EtappiNimi
  : 
  "Leverans slutet vc 33"
  Hylky
  : 
  false
  JM
  : 
  2600
  JarjestysEtapissa
  : 
  27
  Korkeus
  : 
  1800
  M2
  : 
  4.68
  Paino
  : 
  4.759999999999998
  Paksuus
  : 
  1800
  Piirustus
  : 
  "2024-05-07T00:00:00.000Z"
  Pituus
  : 
  2600
  RyhmaNro
  : 
  5342
  ToimitusPVM
  : 
  null
  Tunnus
  : 
  "TYP A"
  TyokohdeNro
  : 
  3965
  ValmistusNro
  : 
  134177
  ValmistusPVM
  : 
  null
  ValmistusTehdas
  : 
  null
  
  
  */

  const sortTableItems = (items) => {
    let nItems = [...items]
    console.log('ITEMSTEST', nItems)

    const sortValue = (key) => {
      nItems.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      });
    }

    const sortDate = (key) => {
      nItems.sort((a, b) => {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);

        if (isNaN(dateA) && isNaN(dateB)) {
          return 0; // If neither has a valid date, maintain original order
        }

        if (isNaN(dateA)) {
          return 1; // a should come after b if a's date is invalid
        }

        if (isNaN(dateB)) {
          return -1; // a should come before b if b's date is invalid
        }

        // Both dates are valid, sort them
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      });
    };



    const sortString = (key, secondaryKey = null) => {
      nItems.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];


        const comparison = sortDirection === 'asc' ? valueA?.localeCompare(valueB) : valueB?.localeCompare(valueA);

        if (comparison !== 0 || !secondaryKey) {
          return comparison;
        }

        // If primary keys are equal, sort by secondary key
        const secondaryValueA = a[secondaryKey];
        const secondaryValueB = b[secondaryKey];
        return secondaryValueA - secondaryValueB
      });
    };

    if (tableFilter === "tunnus") sortString("Tunnus", "ValmistusNro")
    if (tableFilter === "id") sortValue("ValmistusNro")
    if (tableFilter === "pituus") sortValue("Pituus")
    if (tableFilter === "korkeus") sortValue("Korkeus")
    if (tableFilter === "paksuus") sortValue("Paksuus")
    if (tableFilter === "m2") sortValue("M2")
    if (tableFilter === "paino") sortValue("Paino")
    if (tableFilter === "piirustus") sortDate("Piirustus")
    if (tableFilter === "valmistus") sortDate("ValmistusPVM")
    if (tableFilter === "toimitus") sortDate("ToimitusPVM")

    if (tableFilter === "lastcol") {
      if (listType === "elementit") sortString("EtappiNimi")
      if (listType === "etapit") sortString("ValmistusTehdas")
      if (listType === "aikataulu") sortValue("KuormakirjaNumero")
    } 

    return nItems
  }




  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
        <EditStageData
          isOpen={stageDataOpen}
          closeModal={() => {
            setStageDataOpen(false);
          }}
          data={stageData}
          saveFunc={updateStageDatas}
          deleteStage={deleteStage}
          setVerifyDeleteData={setVerifyDeleteData}
          setVerifyDeleteOpen={setVerifyDeleteOpen}
        />
        <NewStage
          isOpen={newStageOpen}
          closeModal={() => {
            setNewStageOpen(false);
          }}
          saveFunc={addNewStage}
          stages={projectData.etapit}
        />

        {/*   const [verifyDeleteOpen, setVerifyDeleteOpen] = useState(false);
  const [verifyDeleteData, setVerifyDeleteDataa] = useState({}); */}

        <VerifyDelete
          isOpen={verifyDeleteOpen}
          closeModal={() => {
            setVerifyDeleteOpen(false);
          }}
          data={verifyDeleteData}
        />

        <div className="projectmain">
          {/* DRAGGING */}
          {useDragging && (
            <div className={`droppablecontainer ${animation}`}>


              {projectData.etapit.length <= 0 ?
                <>

                  <div className={`droppablecontainer-placeholder`}>
                    <div className={`droppablegroup-placeholder`}>
                      <div className={`droppablebox parent`}  >
                        <div className={`droppablebox-header`}>
                          <div>
                            <p className={`droppablebox-placeholder-header-title font-dm-bold`}>
                            {locale.etaps}
                            </p>

                            <p className={`droppablebox-placeholder-text font-dm-regular`}>
                              {locale.etapintroduction}
                            </p>


                            <button
                              className="buttonstyle font-dm-regular"
                              style={{marginLeft:0, marginTop:"1.5em"}}
                              onClick={() => {
                                setNewStageOpen(true);
                              }}
                            >
                              <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                              {locale.etapadd}
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>

{/*                     <div className="etappiBtns">
                      <button
                        className="buttonstyle font-dm-regular"
                        onClick={() => {
                          setNewStageOpen(true);
                        }}
                      >
                        <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                        Lisää etappi
                      </button>
                    </div> */}


                  </div>


                </>

                : <>









                  <div className={`droppablecontainerFixed`}>
                    <div className={`droppablecontainerInner`}>


                      {projectData.etapit.map((etappiItem, indx) => {
                        if (etappiItem.ParentID != 0) return;

                        return (
                          <>
                            <div className={`droppablegroup ${enableEditing}`}>

                              {Object.keys(droppableLists).map((list, i) => {
                                const listdata = droppableLists[list];
                                if (etappiItem.EtappiNro != listdata.parentlist)
                                  return;

                                /*                      console.log(
                                                       "listdata",
                                                       etappiItem.EtappiNro,
                                                       listdata
                                                     ); */





                                return (
                                  <Droppable
                                    key={list}
                                    droppableId={list}
                                    isDropDisabled={!enableEditing}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          className={`droppablebox ${listdata.parent ? "parent" : ""
                                            }`}
                                        >
                                          <div className={`droppablebox-header`}>
                                            <div>
                                              <p className={`droppablebox-header-title font-dm-bold`}>
                                                {listdata.title}
                                              </p>

                                              <p className={`droppablebox-header-date font-dm-bold`}>
                                                {(listdata.startdate ||
                                                  listdata.enddate) &&

                                                  (listdata.startdate
                                                    ? getParsedDate(
                                                      listdata.startdate
                                                    )
                                                    : "") +
                                                  " - " +
                                                  (listdata.enddate
                                                    ? getParsedDate(
                                                      listdata.enddate
                                                    )
                                                    : "")}
                                              </p>
                                            </div>


                                            {!enableEditing && (


                                              <div className="droppablebox-edit">

                                                <EditOutlined
                                                  key={list}
                                                  id={list}
                                                  className="droppablegroup-icon"
                                                  onClick={() => {
                                                    let itemId = parseInt(
                                                      list.replace("LIST_", "")
                                                    );

                                                    handleOpenEtappiData(
                                                      itemId,
                                                      listdata.title,
                                                      listdata.startdate,
                                                      listdata.enddate,
                                                      listdata.parent
                                                    );
                                                  }}
                                                />
                                              </div>

                                            )}



                                            {/*POIS VIELÄ enableEditing && (
                                          <>
                                            <EditOutlined
                                              key={list}
                                              id={list}
                                              style={{ marginRight: 20, marginLeft: 10, marginTop: 5 }}
                                              onClick={() => {
                                                let itemId = parseInt(
                                                  list.replace("LIST_", "")
                                                );

                                                handleOpenEtappiData(
                                                  itemId,
                                                  listdata.title,
                                                  listdata.startdate,
                                                  listdata.enddate
                                                );
                                              }}
                                            />

                                            <div className="droppablebox-multidate">
                                              <DatePicker
                                                getPopupContainer={(trigger) =>
                                                  trigger.parentElement
                                                }
                                                locale={locale2}
                                                size="small"
                                                value={null}
                                                defaultValue={null}
                                                disabled={!enableEditing}
                                                onChange={(date, dateString) => {
                                                  updateDates(list, dateString);
                                                  //console.log(date, dateString);
                                                }}
                                              />


                                            </div>

                                          </>
                                        ) */}

           {/*                                  {!enableEditing && (listdata.parent ? checkParentStageItems(listdata.parentlist) <= 0 : true) && droppableLists[list].items.length <= 0 && (
                                              <div className="droppablebox-delete">
                                                <PlusOutlined
                                                  style={{
                                                    fontSize: 20,
                                                  }}
                                                  className="droppablegroup-icon rotate"
                                                  onClick={() => {



                                                    let itemId = parseInt(
                                                      list.replace("LIST_", "")
                                                    );

                                                    setVerifyDeleteData({
                                                      name: listdata.title,
                                                      parent: listdata.parent,
                                                      verifyFunc: () => {
                                                        deleteStage(itemId);
                                                      },
                                                    });
                                                    setVerifyDeleteOpen(true);

                                                  }}
                                                />
                                              </div>
                                            )} */}
                                          </div>

                                          <div
                                            ref={provided.innerRef}
                                            className="droppableBack"
                                            style={{
                                              backgroundColor:
                                                snapshot.isDraggingOver
                                                  ? "#244cdb3c"
                                                  : "white",
                                            }}
                                          >
                                            <div className="droppableInside">
                                              {droppableLists[list].items.length >=
                                                1
                                                ? droppableLists[list].items.map(
                                                  (item, index) => {
                                                    let itemId = parseInt(
                                                      item.id.replace(
                                                        "_dropped",
                                                        ""
                                                      )
                                                    );

                                                    let rowdata =
                                                      getRowData(itemId);

                                                    /* console.log("DATA:", rowdata, itemId); */

                                                    let elementDate = droppableLists[list].items[index].date
                                                    let isDateBetween = moment(elementDate).isBetween(moment(listdata.startdate), moment(listdata.enddate), 'days', true);


                                                    return (
                                                      <Draggable
                                                        key={item.id}
                                                        draggableId={item.id}
                                                        index={index}
                                                        isDragDisabled={
                                                          !enableEditing
                                                        }
                                                      >
                                                        {(provided, snapshot) => (
                                                          <div
                                                            className="draggableItem"
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={
                                                              provided
                                                                .draggableProps
                                                                .style
                                                            }
                                                          >
                                                            <p
                                                              ro
                                                              className="font-dm-regular draggableItemText">
                                                              {rowdata.Tunnus}

                                                            </p>

                                                            <p className="font-dm-regular draggableItemText2">   {
                                                              rowdata.ValmistusNro
                                                            }</p>


                                                            <DatePicker
                                                              getPopupContainer={(
                                                                trigger
                                                              ) =>
                                                                trigger.parentElement
                                                              }
                                                              placeholder={""}
                                                              format={'DD.MM.YYYY'}
                                                              showWeek={true}
                                                              className={`draggableDatePicker font-dm-regular ${elementDate && !isDateBetween ? 'red' : ''}`}
                                                              size="small"
                                                              value={
                                                                elementDate ? dayjs(elementDate) : null
                                                              }
                                                              disabled={
                                                                !enableEditing
                                                              }
                                                              onChange={(
                                                                date,
                                                                dateString
                                                              ) => {
                                                                let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
                                                                droppableLists[
                                                                  list
                                                                ].items[
                                                                  index
                                                                ].date = saveDate;

                                                                setDroppableLists(
                                                                  {
                                                                    ...droppableLists,
                                                                  }
                                                                );
                                                              }}
                                                            />

                                                            {enableEditing && (
                                                              <PlusOutlined
                                                                style={{
                                                                  fontSize: 20,
                                                                }}
                                                                className="draggableItemClose"
                                                                onClick={() => {
                                                                  removeDraggable(
                                                                    list,
                                                                    item.id
                                                                  );
                                                                }}
                                                              />
                                                            )}
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    );
                                                  }
                                                )
                                                : null}
                                              {provided.placeholder}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                );
                              })}
                            </div>
                          </>
                        );
                      })}

                      <></>
                    </div>

                    {!enableEditing && (
                      <div className="etappiBtns">
                        <button
                          className="buttonstyle font-dm-regular"
                          onClick={() => {
                            setNewStageOpen(true);
                          }}
                        >
                          <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                          {locale.etapadd}
                        </button>

                        <button
                          className="buttonstyle font-dm-regular"
                          onClick={() => {
                            setCopiedList(
                              JSON.parse(JSON.stringify(droppableLists))
                            );
                            setEnableEditing(!enableEditing);
                          }}
                        >
                          <MergeCellsOutlined
                            style={{ fontSize: 15, marginRight: -5 }}
                          />
                          {locale.etapcontrol2}
                        </button>
                      </div>
                    )}

                    {enableEditing && (
                      <div className="etappiBtns">
                        <button
                          className="buttonstyle font-dm-regular"
                          style={{
                            backgroundColor: "#25D155",
                            width: "150px",
                          }}
                          onClick={() => {
                            setCopiedList(droppableLists);
                            setEnableEditing(!enableEditing);
                            updateStages();
                            getProject();
                          }}
                        >
                          {locale.save}
                        </button>

                        <button
                          className="buttonstyle font-dm-regular"
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid grey",
                            /*                      borderWidth:1,
                            borderColor: 'grey', */
                            color: "#575757",
                            width: "150px",
                          }}
                          onClick={() => {
                            //console.log("PERUUTA COPIED", copiedList);

                            setDroppableLists(copiedList);
                            setEnableEditing(!enableEditing);
                            getProject();
                          }}
                        >
                          {locale.cancel}
                          </button>
                      </div>
                    )}
                  </div>
                </>}
            </div>
          )}


          <div className={`projectpage-content ${animation}`}>



            <LeftOutlined className="back-arrow" onClick={()=>{ navigate(-1) }}/>


            {!isMobile ?
              <p className="project-header-id font-dm-bold">
                {projectData.tiedot.Tyokohdenro}
              </p>
              : <></>}


            <h1 className="project-header font-itc">
              {isMobile ?
                projectData.tiedot.Tyokohdenro
                : ""} {projectData.tiedot.Tyokohde}
            </h1>
            <div className="project-header-headeritems">
              <div className="item">
                <p className="label font-dm-regular">{locale.ordered}</p>
                <p className="value font-dm-bold">
                  {projectData.tiedot.TilattuKplYht || 0}
                </p>
              </div>

              <div className="item">
                <p className="label font-dm-regular">{locale.blueprints}</p>
                <p className="value font-dm-bold">
                  {projectData.tiedot.PiirustuksiaKplYht || 0}
                </p>
              </div>

              <div className="item">
                <p className="label font-dm-regular">{locale.prepared}</p>
                <p className="value font-dm-bold">
                  {projectData.tiedot.ValmistettuKplYht || 0}
                </p>
              </div>

              <div className="item">
                <p className="label font-dm-regular">{locale.booked}</p>
                <p className="value font-dm-bold">
                  {projectData.tiedot.ToimitettuKplYht || 0}
                </p>
              </div>

              {!isMobile ?

                <div className="items-right">
                  <button
                    className="buttonstyle font-dm-regular"
                    onClick={async () => {

                      if (pdfLoading) return

                      setPdfLoading(true)

                      let resp = await getPdf(
                        listType == "elementit"
                          ? `${envConfig.apiUrlPrefix}getProjectGroupsPdf`
                          : `${envConfig.apiUrlPrefix}getProjectStagesPdf`,
                        {
                          projectId: projectData.tiedot.Tyokohdenro,
                          localeId: lang,
                        }
                      )

                      console.log('PDFresp', resp)

                      setPdfLoading(false)



                    }
                    }
                  >
                    <FilePdfOutlined style={{ fontSize: 17, marginRight: -5 }} />
                    <p>{locale.pdfreport}</p>


                    {pdfLoading ? <LoadingItem /> : ''}
                  </button>


                  {!isMediumWidth &&
                    <button
                      className={`buttonstyle font-dm-regular ${enableEditing && "disabled"}`}
                      disabled={enableEditing}
                      onClick={() => handleShowDragging()}
                    >
                      <MergeCellsOutlined
                        style={{ fontSize: 17, marginRight: -5 }}
                      />

                      {useDragging ? locale.etapcontrolhide : locale.etapcontrol}
                    </button>
                  }
                </div>
                : <></>}
            </div>


            {/* Elementtiryhmät */}
            {listType == "elementit" && (
              <div className="project-box">


                <div className="project-box-header">
                  <p className="project-box-name font-dm-bold">
                    {locale.production}
                  </p>

                    <p className="project-box-right font-dm-bold">
                    {locale.elementgroups}
                      <Dropdown
                        className="lngDropdown"
                        menu={{
                          items: [
                            {
                              key: 'etappi',
                              label: (
                                <p
                                  className="menuValue font-dm-medium"
                                  onClick={() => {
                                    setListType("etapit")
                                    setMenuVisible(false);
                                  }}
                                >
                                  {locale.etaps}
                                </p>
                              ),
                            },
                            {
                              key: 'aikataulu', label: (
                                <p
                                  className="menuValue font-dm-medium"
                                  onClick={() => {
                                    setListType("aikataulu")
                                    setMenuVisible(false);
                                  }}
                                >
                                  {locale.schedule}
                                </p>
                              ),
                            }
                          ]
                        }
                        }
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <MenuOutlined style={{ fontSize: 17, marginLeft: 10, marginRight: 5 }} />
                      </Dropdown>
                    </p>
                </div>
                <ProgressBar
                  style={{ marginLeft: isMobile ? 0 : 10, marginRight:  isMobile ? 0 : 10, marginBottom: 10 }}
                  backgroundColor={"#14DC40"}
                  data1={projectData.tiedot.TilattuKplYht}
                  data2={projectData.tiedot.ValmistettuKplYht}
                />

                {projectData.elementtiryhmat.length >= 1 &&
                  projectData.elementtiryhmat.map((item, index) => {
                    console.log('TEST', item)
                    let isVälitys = false;

                    if (
                      tehtaat[item.Tehdas] &&
                      tehtaat[item.Tehdas].name == "Välitys"
                    ) {
                      isVälitys = true;
                    }

                    return (
                      <>
                        <div
                          key={item.ElementtiRyhmaNro}
                          className="project-p-item"
                        >
                          <div
                            className="project-p-header"
                            onClick={() => {
                              if (!isVälitys)
                                handleOpenElement(item.ElementtiRyhmaNro);
                            }}
                          >

                            <div className="item-title">
                              <p
                                className={`name ${isVälitys ? "font-dm-regular" : "font-dm-bold"
                                  }`}
                              >
                                {item.ElementtiRyhma}
                              </p>

                              <div className="item-footer">
                                <p className="factory font-dm-regular">
                                  {tehtaat[item.Tehdas] &&
                                    tehtaat[item.Tehdas].name}
                                </p>
                                {isMobile ?
                                  <div className="item-numbers">
                                    <p className="number font-dm-regular">
                                      {item.TilattuKpl || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>
                                    <p className="number font-dm-regular">
                                      {item.PiirustuksiaKpl || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                                    </p>

                                    <p className="number font-dm-regular">
                                      {item.ValmistettuKpl || 0} <Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>

                                    <p className="number font-dm-regular">
                                      {item.ToimitettuKpl || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>
                                  </div>
                                  : <></>}
                              </div>
                            </div>


                            {!isMobile ? <>
                              <div className="item-labelvalue">
                                <p className="label font-dm-regular">
                                  {locale.ordered}
                                </p>
                                <p
                                  className={`value ${isVälitys
                                    ? "font-dm-regular"
                                    : "font-dm-bold"
                                    }`}
                                >
                                  {item.TilattuKpl || 0}
                                </p>
                              </div>

                              <div className="item-labelvalue">
                                <p className="label font-dm-regular">
                                  {locale.blueprints}
                                </p>
                                <p
                                  className={`value ${isVälitys
                                    ? "font-dm-regular"
                                    : "font-dm-bold"
                                    }`}
                                >
                                  {item.PiirustuksiaKpl || 0}
                                </p>
                              </div>

                              <div className="item-labelvalue">
                                {!isVälitys && (
                                  <>
                                    <p className="label font-dm-regular">
                                      {locale.prepared}
                                    </p>
                                    <p className="value font-dm-bold">
                                      {item.ValmistettuKpl || 0}
                                    </p>
                                  </>
                                )}
                              </div>

                              <div className="item-labelvalue">
                                {!isVälitys && (
                                  <>
                                    <p className="label font-dm-regular">
                                      {locale.booked}
                                    </p>
                                    <p className="value font-dm-bold">
                                      {item.ToimitettuKpl || 0}
                                    </p>
                                  </>
                                )}
                              </div>
                            </>
                              : <></>}


                            <div className="item-arrow">
                              {elementListOpen[item.ElementtiRyhmaNro]
                                ? <UpOutlined className="item-arrow-icon" style={{}} />
                                : <RightOutlined className="item-arrow-icon" style={{}} />
                              }
                            </div>
                          </div>

                          {elementListOpen[item.ElementtiRyhmaNro] && (
                            <>
                              <div className="project-p-table">

                                <RenderListHeader
                                  isMobile={isMobile}
                                  isMediumWidth={isMediumWidth}
                                  listType={listType}
                                  tableFilter={tableFilter}
                                  setTableFilter={setTableFilter}
                                  sortDirection={sortDirection}
                                  setSortDirection={setSortDirection}
                                />


                                <Droppable
                                  droppableId={
                                    "GROUP_" + item.ElementtiRyhmaNro
                                  }
                                  isDropDisabled={true}
                                >
                                  {(provided, snapshot) => {
                                    /* console.log("snapshot", snapshot); */

                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        onClickCapture={() => {
                                          //console.log("TESTASD");
                                        }}
                                      >
                                        {item.data &&
                                          item.data.length >= 1 &&
                                          [...sortTableItems(item.data)].map((item2, index2) => {
                                            return (
                                              <DraggableRowItem
                                                index={index2}
                                                itemId={item2.ValmistusNro}
                                                item={item2}
                                                listType={listType}
                                                dragDisabled={
                                                  !useDragging || !enableEditing
                                                }
                                                itemsInDroppable={
                                                  itemsInDroppable
                                                }
                                                isMobile={isMobile}
                                                isMediumWidth={isMediumWidth}
                                              />
                                            );
                                          })}

                                        <div style={{ display: "none" }}>
                                          {provided.placeholder}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Droppable>

                                <RenderListFooter
                                  isMobile={isMobile}
                                  isMediumWidth={isMediumWidth}
                                  total={item?.data?.length}
                                  m2={item.Neliot}
                                  paino={item.Tonnit}
                                  piirustus={item.PiirustuksiaKpl}
                                  valmistus={item.ValmistettuKpl}
                                  toimitus={item.ToimitettuKpl}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
              </div>
            )}




            {/* Etapit */}
            {listType == "etapit" && (
              <>
                <div className="project-box">
                  <div className="project-box-header">
                    <p className="project-box-name font-dm-bold">
                      {locale.production}
                    </p>
                    <p className="project-box-right font-dm-bold">
                    {locale.etaps}
                      <Dropdown
                        className="lngDropdown"
                        menu={{
                          items: [
                            {
                              key: 'elementti', label: (
                                <p
                                  className="menuValue font-dm-medium"
                                  onClick={() => {
                                    setListType("elementit")
                                    setMenuVisible(false);
                                  }}
                                >
                                  {locale.elementgroups}
                                </p>
                              ),
                            },
                            {
                              key: 'aikataulu', label: (
                                <p
                                  className="menuValue font-dm-medium"
                                  onClick={() => {
                                    setListType("aikataulu")
                                    setMenuVisible(false);
                                  }}
                                >
                                  {locale.schedule}
                                </p>
                              ),
                            }
                          ]
                        }
                        }
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <MenuOutlined style={{ fontSize: 17, marginLeft: 10, marginRight: 5 }} />
                      </Dropdown>
                    </p>
                  </div>

                  {projectData.etapit.map((etappiBase, indx) => {
                    console.log('test2', etappiBase)
                    if (etappiBase.ParentID != 0) return;
                    let etapityhteenveto = projectData.etapityhteenveto;
                    let yhtData = etapityhteenveto.find(
                      (obj) => obj.EtappiNro === etappiBase.EtappiNro
                    );

                    return (
                      <>
                        <div key={indx} className="project-p-item">

                          <div
                            className="project-p-header"
                            onClick={() => {
                              handleOpenEtappi(etappiBase.EtappiNro);
                            }}
                          >
      {/*                       <div className="item-title">
                              <p className={`name font-dm-bold`}>
                                {etappiBase.Etappi}
                              </p>
                              <p className="factory font-dm-regular">
                                {(etappiBase?.ArvioituToimituksenAlku || etappiBase?.ArvioituToimituksenLoppu) &&
                                  (etappiBase?.ArvioituToimituksenAlku
                                    ? getParsedDate(
                                      etappiBase?.ArvioituToimituksenAlku
                                    )
                                    : "") +
                                  " - " +
                                  (etappiBase?.ArvioituToimituksenLoppu
                                    ? getParsedDate(
                                      etappiBase?.ArvioituToimituksenLoppu
                                    )
                                    : "")
                                }
                              </p>
                            </div> */}

                            <div className="item-title">
                              <p
                                className={`name font-dm-bold`}
                              >
                              {etappiBase.Etappi}
                              </p>

                              <div className="item-footer">


                                {!isMobile &&
                                <p className="factory font-dm-regular" style={{fontSize:13, alignSelf:'center'}}>
                                {(etappiBase?.ArvioituToimituksenAlku || etappiBase?.ArvioituToimituksenLoppu) &&
                                  (etappiBase?.ArvioituToimituksenAlku
                                    ? getParsedDate(
                                      etappiBase?.ArvioituToimituksenAlku
                                    )
                                    : "") +
                                  " - " +
                                  (etappiBase?.ArvioituToimituksenLoppu
                                    ? getParsedDate(
                                      etappiBase?.ArvioituToimituksenLoppu
                                    )
                                    : "")
                                }
                                </p>
                                 }
                                {isMobile ?
                                  <div className="item-numbers">
                                    <p className="number font-dm-regular">
                                    {parentEtappiTotal[etappiBase.EtappiNro]?.total || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>
                                    <p className="number font-dm-regular">
                                    {parentEtappiTotal[etappiBase.EtappiNro]?.piirustus || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                                    </p>

                                    <p className="number font-dm-regular">
                                    {parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0} <Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>

                                    <p className="number font-dm-regular">
                                    {parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>
                                  </div>
                                  : <></>}
                              </div>
                            </div>



                            {!isMobile ? <> 


                            <div className="item-labelvalue">
                              <p className="label font-dm-regular">
                                {locale.ordered}
                              </p>
                              <p
                                className={`value font-dm-bold`}
                              >
                                {parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                              </p>
                            </div>

                            <div className="item-labelvalue">
                              <p className="label font-dm-regular">
                                {locale.blueprints}
                              </p>
                              <p
                                className={`value font-dm-bold`}
                              >
                                {parentEtappiTotal[etappiBase.EtappiNro]?.piirustus || 0}
                              </p>
                            </div>

                            <div className="item-labelvalue">

                              <p className="label font-dm-regular">
                                {locale.prepared}
                              </p>
                              <p className="value font-dm-bold">
                                {parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0}
                              </p>

                            </div>

                            <div className="item-labelvalue">
                              <p className="label font-dm-regular">
                                {locale.booked}
                              </p>
                              <p className="value font-dm-bold">
                                {parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0}
                              </p>
                            </div>
                            </>
                          : <></>}
                          </div>
               

                          {etappiListOpen[etappiBase.EtappiNro] && (
                            <>

                              <div className="dividerTest"></div>

                              <ProgressBar
                                style={{ marginLeft:  isMobile ? 0 : 10, marginRight:  isMobile ? 0 : 10, marginBottom: 10 }}
                                backgroundColor={"#14DC40"}
                                data1={parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                                data2={parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0}
                              />

                              <ProgressBar
                                style={{ marginLeft:  isMobile ? 0 : 10, marginRight:  isMobile ? 0 : 10, marginBottom: 10 }}
                                backgroundColor={"#2B4BF2"}
                                data1={parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                                data2={parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0}
                              />


                              {projectData.etapit.map((etappiItem, indx2) => {
                                //console.log('createetap', etappiItem.Etappi, etappiBase.EtappiNro, etappiItem.ParentID)
                                if ((etappiBase.EtappiNro != etappiItem.EtappiNro) && (etappiBase.EtappiNro != etappiItem.ParentID)) return;

                                //if (etappiBase.EtappiNro != etappiItem.EtappiNro) return
                                return (

                                  <div key={indx2}>

                                    {etappiBase.EtappiNro === etappiItem.ParentID ?

                                      <p className="project-box-name font-dm-bold" style={{ marginBottom: 0 }}>
                                        {etappiItem.Etappi}
                                      </p>
                                      : <></>}



                                    <div className="project-p-table">
                                      <RenderListHeader
                                        isMobile={isMobile}
                                        isMediumWidth={isMediumWidth}
                                        listType={listType}
                                        tableFilter={tableFilter}
                                        setTableFilter={setTableFilter}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                      />

                                      <Droppable
                                        droppableId={
                                          "GROUP_" + etappiItem.EtappiNro
                                        }
                                        isDropDisabled={true}
                                      >
                                        {(provided, snapshot) => {
                                          /* console.log("snapshot", snapshot); */
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              onClickCapture={() => {
                                                // console.log("TESTASD");
                                              }}
                                            >
                                              {projectData.elementit &&
                                                projectData.elementit.length >=
                                                1 &&
                                                [...sortTableItems(projectData.elementit)].map(
                                                  (item2, index2) => {
                                                    if (
                                                      item2.Etappi !=
                                                      etappiItem.EtappiNro
                                                    )
                                                      return;
                                                    return (
                                                      <DraggableRowItem
                                                        index={index2}
                                                        itemId={
                                                          item2.ValmistusNro
                                                        }
                                                        item={item2}
                                                        listType={listType}
                                                        dragDisabled={
                                                          !useDragging ||
                                                          !enableEditing
                                                        }
                                                        itemsInDroppable={
                                                          itemsInDroppable
                                                        }
                                                        isMobile={isMobile}
                                                        isMediumWidth={isMediumWidth}
                                                      />
                                                    );
                                                  }
                                                )}

                                              <div style={{ display: "none" }}>
                                                {provided.placeholder}
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Droppable>

                                      <RenderListFooter
                                        isMobile={isMobile}
                                        isMediumWidth={isMediumWidth}
                                        total={etappiTotal[etappiItem.EtappiNro]?.total}
                                        m2={etappiTotal[etappiItem.EtappiNro]?.m2}
                                        paino={etappiTotal[etappiItem.EtappiNro]?.paino}
                                        piirustus={etappiTotal[etappiItem.EtappiNro]?.piirustus}
                                        valmistus={etappiTotal[etappiItem.EtappiNro]?.valmistus}
                                        toimitus={etappiTotal[etappiItem.EtappiNro]?.toimitus}
                                      />

                                    </div>
                                  </div>

                                )
                              })}
                            </>
                          )}

                        </div>
                      </>
                    )
                  })}
                </div>


                {/* ELEMENTIT JOILlA EI OLE VALITTUA ETAPPIA */}
                {projectData?.elementit?.filter(itm2 => itm2.Etappi === null).length >= 1 && (
                  <div className="project-box">
                    <p className="project-box-name font-dm-bold">
                      {locale.nophases}
                    </p>

                    {true && (
                      <>
                        <div className="project-p-table">
                          <RenderListHeader
                            isMobile={isMobile}
                            isMediumWidth={isMediumWidth}
                            listType={listType}
                            tableFilter={tableFilter}
                            setTableFilter={setTableFilter}
                            sortDirection={sortDirection}
                            setSortDirection={setSortDirection}
                          />
                          <Droppable
                            droppableId={"GROUP_" + "OTHERS"}
                            isDropDisabled={true}
                          >
                            {(provided, snapshot) => {
                              /* console.log("snapshot", snapshot); */
                              return (
                                <div
                                  ref={provided.innerRef}
                                  onClickCapture={() => {
                                    // console.log("TESTASD");
                                  }}
                                >
                                  {projectData.elementit &&
                                    projectData.elementit.length >= 1 &&
                                    [...sortTableItems(projectData.elementit)].map(
                                      (item2, index2) => {
                                        if (item2.Etappi) return;

                                        return (
                                          <DraggableRowItem
                                            index={index2}
                                            itemId={item2.ValmistusNro}
                                            item={item2}
                                            listType={listType}
                                            dragDisabled={
                                              !useDragging || !enableEditing
                                            }
                                            itemsInDroppable={itemsInDroppable}
                                            isMobile={isMobile}
                                            isMediumWidth={isMediumWidth}
                                          />
                                        );
                                      }
                                    )}

                                  <div style={{ display: "none" }}>
                                    {provided.placeholder}
                                  </div>
                                </div>
                              );
                            }}
                          </Droppable>

                          <RenderListFooter
                            isMobile={isMobile}
                            isMediumWidth={isMediumWidth}
                            total={noEtappiTotal?.total}
                            m2={noEtappiTotal?.m2}
                            paino={noEtappiTotal?.paino}
                            piirustus={noEtappiTotal?.piirustus}
                            valmistus={noEtappiTotal?.valmistus}
                            toimitus={noEtappiTotal?.toimitus}
                          />

                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}







            {/* AIKATAULU NÄKYMÄ */}
            {listType == "aikataulu" && (
              <>
                <div className="project-box">
                  <div className="project-box-header">
                    <p className="project-box-name font-dm-bold">
                      {locale.production}
                    </p>
                    <p className="project-box-right font-dm-bold">
                    {locale.schedule}
                      <Dropdown
                        className="lngDropdown"
                        menu={{
                          items: [
                            {
                              key: 'elementti', label: (
                                <p
                                  className="menuValue font-dm-medium"
                                  onClick={() => {
                                    setListType("elementit")
                                    setMenuVisible(false);
                                  }}
                                >
                                  {locale.elementgroups}
                                </p>
                              ),
                            },
                            {
                              key: 'etapit', label: (
                                <p
                                  className="menuValue font-dm-medium"
                                  onClick={() => {
                                    setListType("etapit")
                                    setMenuVisible(false);
                                  }}
                                >
                                   {locale.etaps}
                                </p>
                              ),
                            }
                          ]
                        }
                        }
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <MenuOutlined style={{ fontSize: 17, marginLeft: 10, marginRight: 5 }} />
                      </Dropdown>
                    </p>
                  </div>

                  {Object.keys(aikatauluData).map((dateKey, indx) => {
                    const dateData = aikatauluData[dateKey]

                    return (
                      <>
                        <div key={indx} className="project-p-item">

                          <div
                            className="project-p-header"
                            onClick={() => {
                              handleOpenAikataulu(dateKey);
                            }}
                          >
                            <div className="item-title">
                              <p className={`name font-dm-bold`}>
                                {dateKey}
                              </p>



                              {isMobile ?
                                  <div className="item-numbers">
                                    <p className="number font-dm-regular">
                                    {dateData?.total || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>
                                    <p className="number font-dm-regular">
                                    {dateData?.piirustus || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                                    </p>

                                    <p className="number font-dm-regular">
                                    {dateData?.valmistus || 0}<Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>

                                    <p className="number font-dm-regular">
                                    {dateData?.toimitus || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                                    </p>
                                  </div>
                                  : <></>}


                            </div>


                            {!isMobile ? <>

                            <div className="item-labelvalue">
                              <p className="label font-dm-regular">
                                {locale.ordered}
                              </p>
                              <p
                                className={`value font-dm-bold`}
                              >
                                {dateData?.total || 0}
                              </p>
                            </div>

                            <div className="item-labelvalue">
                              <p className="label font-dm-regular">
                                {locale.blueprints}
                              </p>
                              <p
                                className={`value font-dm-bold`}
                              >
                                {dateData?.piirustus || 0}
                              </p>
                            </div>

                            <div className="item-labelvalue">

                              <p className="label font-dm-regular">
                                {locale.prepared}
                              </p>
                              <p className="value font-dm-bold">
                                {dateData?.valmistus || 0}
                              </p>

                            </div>

                            <div className="item-labelvalue">
                              <p className="label font-dm-regular">
                                {locale.booked}
                              </p>
                              <p className="value font-dm-bold">
                                {dateData?.toimitus || 0}
                              </p>
                            </div>
                            </> : <></>}
                          </div>


                          {aikatauluListOpen[dateKey] && (
                            <>

                              <div className="dividerTest"></div>

                              <ProgressBar
                  style={{ marginLeft: isMobile ? 0 : 10, marginRight:  isMobile ? 0 : 10, marginBottom: 10 }}

                                backgroundColor={"#14DC40"}
                                data1={dateData?.total || 0}
                                data2={dateData?.valmistus || 0}
                              />

                              <ProgressBar
                  style={{ marginLeft: isMobile ? 0 : 10, marginRight:  isMobile ? 0 : 10, marginBottom: 10 }}

                                backgroundColor={"#2B4BF2"}
                                data1={dateData?.total || 0}
                                data2={dateData?.toimitus || 0}
                              />


                            
                              <div className="project-p-table">
                                <RenderListHeader
                                  isMobile={isMobile}
                                  isMediumWidth={isMediumWidth}
                                  listType={listType}
                                  tableFilter={tableFilter}
                                  setTableFilter={setTableFilter}
                                  sortDirection={sortDirection}
                                  setSortDirection={setSortDirection}
                                />

                                <Droppable
                                  droppableId={
                                    "GROUP_" + dateKey
                                  }
                                  isDropDisabled={true}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        onClickCapture={() => {
                                        }}
                                      >
                                        {dateData.elements &&
                                          dateData.elements?.length >=
                                          1 &&
                                          [...sortTableItems(dateData.elements)].map(
                                            (item2, index2) => {
                                              return (
                                                <DraggableRowItem
                                                  index={index2}
                                                  itemId={
                                                    item2.ValmistusNro
                                                  }
                                                  item={item2}
                                                  listType={listType}
                                                  dragDisabled={
                                                    !useDragging ||
                                                    !enableEditing
                                                  }
                                                  itemsInDroppable={
                                                    itemsInDroppable
                                                  }
                                                  isMobile={isMobile}
                                                  isMediumWidth={isMediumWidth}
                                                />
                                              );
                                            }
                                          )}

                                        <div style={{ display: "none" }}>
                                          {provided.placeholder}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Droppable>

                                <RenderListFooter
                                  isMobile={isMobile}
                                  isMediumWidth={isMediumWidth}
                                  total={dateData?.total}
                                  m2={dateData?.m2}
                                  paino={dateData?.paino}
                                  piirustus={dateData?.piirustus}
                                  valmistus={dateData?.valmistus}
                                  toimitus={dateData?.toimitus}
                                />

                              </div>

                      
                            </>
                          )}

                        </div>
                      </>
                    )
                  })}
                </div>


                {/* ELEMENTIT JOILlA EI OLE aikataulua */}
                {noAikatauluData.elements.length >= 1 && (
                  <div className="project-box">
                    <p className="project-box-name font-dm-bold">
                      {locale.noscheduletext}
                    </p>

                    {true && (
                      <>
                        <div className="project-p-table">
                          <RenderListHeader
                            isMobile={isMobile}
                            isMediumWidth={isMediumWidth}
                            listType={listType}
                            tableFilter={tableFilter}
                            setTableFilter={setTableFilter}
                            sortDirection={sortDirection}
                            setSortDirection={setSortDirection}
                          />
                          <Droppable
                            droppableId={"GROUP_" + "OTHERS"}
                            isDropDisabled={true}
                          >
                            {(provided, snapshot) => {
                              /* console.log("snapshot", snapshot); */
                              return (
                                <div
                                  ref={provided.innerRef}
                                  onClickCapture={() => {
                                    // console.log("TESTASD");
                                  }}
                                >
                                  {noAikatauluData.elements &&
                                    noAikatauluData.elements.length >= 1 &&
                                    [...sortTableItems(noAikatauluData.elements)].map(
                                      (item2, index2) => {

                                        return (
                                          <DraggableRowItem
                                            index={index2}
                                            itemId={item2.ValmistusNro}
                                            item={item2}
                                            listType={listType}
                                            dragDisabled={
                                              !useDragging || !enableEditing
                                            }
                                            itemsInDroppable={itemsInDroppable}
                                            isMobile={isMobile}
                                            isMediumWidth={isMediumWidth}
                                          />
                                        );
                                      }
                                    )}

                                  <div style={{ display: "none" }}>
                                    {provided.placeholder}
                                  </div>
                                </div>
                              );
                            }}
                          </Droppable>

                          <RenderListFooter
                            isMobile={isMobile}
                            isMediumWidth={isMediumWidth}
                            total={noEtappiTotal?.total}
                            m2={noEtappiTotal?.m2}
                            paino={noEtappiTotal?.paino}
                            piirustus={noEtappiTotal?.piirustus}
                            valmistus={noEtappiTotal?.valmistus}
                            toimitus={noEtappiTotal?.toimitus}
                          />

                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}












            {/* TOIMITUS BOXI */}
            {listType == "elementit" && (
              <div className="project-box">
                <p className="project-box-name font-dm-bold">{locale.deliveryheader}</p>

                <ProgressBar
                  style={{ marginLeft: isMobile ? 0 : 10, marginRight:  isMobile ? 0 : 10, marginBottom: 10 }}

                  backgroundColor={"#2B4BF2"}
                  data1={projectData.tiedot.ValmistettuKplYht}
                  data2={projectData.tiedot.ToimitettuKplYht}
                />

                {projectData.kuormakirja.length >= 1 &&
                  projectData.kuormakirja.map((item, index) => {


                    let hour = getParsedTime(item.PurkuKlo)
                    let classColor

                    if (item.Lastaaja) {
                      if (item.LastausAlku && item.LastausLoppu) {
                        classColor = "listTrue "
                      } else {
                        classColor = "listHalf "
                      }
                    } else {
                      classColor = "listFalse "
                    }

                    return (
                      <>
                        <div
                          key={item.KuormakirjaNumero}
                          className="project-p-item"
                        >
                          <div
                            className="project-p-header"
                            onClick={() => {
                              /* handleOpenElement(item.ElementtiRyhmaNro); */
                            }}
                          >
                            <div className="item-title">
                              <p className={`name font-dm-bold ${classColor}`}>
                                {item.KuormakirjaNumero}
                              </p>

                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <p className="factory font-dm-regular">
                                  {tehtaat[item.Tehdas] &&
                                    tehtaat[item.Tehdas].name}
                                </p>

                                {!isMobile ? <>
                                  <p className="factory font-dm-regular oneLine">
                                    {item.AutonNimi}
                                  </p>

                                  {item.SuunniteltuLastausaika && !isMediumWidth && (
                                    <div className="oneLine">
                                      <p className={"factory font-dm-regular"}>
                                        Lastaus:
                                      </p>
                                      <p className={"factory font-dm-regular"}>
                                        {getParsedDate(item.SuunniteltuLastausaika)}
                                      </p>
                                      <p className={"factory font-dm-regular"}>
                                        {getParsedTimeNoZero(item.SuunniteltuLastausaika)}
                                      </p>
                                    </div>
                                  )}
                                </> : <></>}
                              </div>



                            </div>

                            <div className="item-labelvalue">
                              <p className={`value font-dm-bold ${classColor}`}>
                                {item?.Maara || 0}
                              </p>
                              <p className={`label font-dm-regular ${classColor}`}>kpl</p>
                            </div>

                            <div className="item-labelvalue">
                              <p className={`value font-dm-bold ${classColor}`}>
                                {item?.ElementtienPaino.toFixed(1) || 0}
                              </p>
                              <p className={`label font-dm-regular ${classColor}`}>t</p>
                            </div>

                            <div className="item-labelvalue">
                              <p className={`value font-dm-bold ${classColor}`}>
                                {getParsedDate(item?.ToimitusPVM) || 0}
                              </p>
                            </div>


                            {!isMobile ? <>
                              <div className="item-labelvalue">
                                <p className={`value font-dm-bold ${classColor}`}>
                                  {hour}
                                </p>
                              </div>
                            </> : <></>}

                            <div className="item-pdf">
                              <PdfButton
                                url={`${envConfig.apiUrlPrefix}getDeliveryPdf`}
                                data={{
                                  deliveryId: item.KuormakirjaNumero,
                                  projectId: projectData.tiedot.Tyokohdenro,
                                  useSqlLocale: false,
                                  localeId: lang,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </DragDropContext>
    </>
  );
};
