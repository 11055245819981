
import ReactLoading from 'react-loading';
import "./Loading.css";


export function Loading({color}) {
  return (
    <div className='loaderContainer'>
      <ReactLoading className='loader' type={'spokes'} color={'black'} height={'4vh'} width={'4vh'} delay={0} />
    </div>
  );
}



export function LoadingItem({color="white"}) {
  return (
      <ReactLoading className='loader' type={'spokes'} color={color} height={"15px"} width={'15px'} delay={0} />
  );
}

