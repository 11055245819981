import { useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import "./Projects.css";

import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import { getEnvSettings } from "../../config/config";

const ItemValue = ({ v1, v2, v3, v4 }) => {
  const { locale } = useTranslate();

  return (
    <div className="homepage-card-values">
      <div className="group">
        <p className="label font-dm-medium">{locale.ordered}</p>
        <p className="value font-dm-bold">{v1}</p>
      </div>

      <div className="group">
        <p className="label font-dm-medium">{locale.blueprints}</p>
        <p className="value font-dm-bold">{v2}</p>
      </div>

      <div className="group">
        <p className="label font-dm-medium">{locale.prepared}</p>
        <p className="value font-dm-bold">{v3}</p>
      </div>

      <div className="group">
        <p className="label font-dm-medium">{locale.booked}</p>
        <p className="value font-dm-bold">{v4}</p>
      </div>
    </div>
  );
};

export const Projects = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  // const [projects, setProjects] = useState([]);
  const [projectsWorking, setProjectsWorking] = useState([])
  const [projectsReady, setProjectsReady] = useState([])
  const [renderArray, setRenderArray] = useState({})
  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  const { selectedFactory } = useSelector(
    (state) => state.currentUser.user
  );

  const [filter, setFilter] = useState("tuotanto")



  const getProjects = () => {
    setisLoading(true);
    console.log("getProjects");

    let req_config = {
      method: envConfig.env === "intra" ? "POST" : "GET",
      url: `${envConfig.apiUrlPrefix}getProjects`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        tehdas: selectedFactory
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);


        if (envConfig.env === "intra") {
          setProjectsReady(response.data.data.valmiit)
          setProjectsWorking(response.data.data.tuotannossa)
          setRenderArray(response.data.data.tuotannossa)
          setFilter("tuotanto")
        } else {
          setRenderArray(response.data.data)
        }

        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getProjects();
    //console.log("useEffect called");
  }, [selectedFactory]);

  useEffect(() => {
    if (filter == 'tuotanto') {
      setRenderArray(projectsWorking)
    } else if (filter == 'valmiit') {
      setRenderArray(projectsReady)
    } else {
      setRenderArray('')
    }
  }, [filter])

  if (isLoading) {
    return <Loading />;
  }

  const openProject = (id) => {
    console.log("openProject2", id);
    console.log("TEST: ", `/project/${id}`);
    navigate(`/project/${id}`);
  };


  console.log('TESTIENV', process.env.REACT_APP_ENV)

  return (
    <>
      <div className="projects-content">
        {/*     <h1 className="homepage-header font-itc">{locale.slogan}</h1> */}


        {envConfig.env === "intra" ?
          <div className="projects-filters">
            <div className="projects-filters-btns">
              <div className={filter == "tuotanto" ? "filter-button left active" : "filter-button left inactive"}
                onClick={() => {
                  setFilter("tuotanto")
                }}
              >
                <p className={filter == "tuotanto" ? "button-text-active" : "button-text-inactive"}>{locale.production}</p>
              </div>

              <div className={filter == "valmiit" ? "filter-button right active" : "filter-button right inactive"}
                onClick={() => {
                  setFilter("valmiit")
                }}
              >
                <p className={filter == "valmiit" ? "button-text-active" : "button-text-inactive"}>{locale.isready}</p>
              </div>
            </div>
          </div>
          : <></>}

        <div className="projects-projects">
          {renderArray?.length >= 1 ?
            renderArray.map((item, index) => {
              console.log("ITEM:_", item)
              return (

                <div className="projectItem"
                  onClick={() => {
                    openProject(item.Tyokohdenro);
                  }}
                >
                  <div className="projectRow">
                    <div>
                      <p className="projectNumber">{item.Tyokohdenro}</p>
                      <p className="projectTitle">{item.Tyokohde}</p>

                      <div className="projectDetails">
                        <div className="projectDetailBox detailFirst">
                          <p className="ProjectSmallText">{locale.ordered}</p>
                          <p className="projectText">{item.TilattuKplYht}</p>
                        </div>

                        <div className="projectDetailBox">
                          <p className="ProjectSmallText">{locale.blueprints}</p>
                          <p className="projectText">{item.PiirustuksiaKplYht}</p>
                        </div>

                        <div className="projectDetailBox">
                          <p className="ProjectSmallText">{locale.prepared}</p>
                          <p className="projectText">{item.ValmistettuKplYht}</p>
                        </div>

                        <div className="projectDetailBox">
                          <p className="ProjectSmallText">{locale.booked}</p>
                          <p className="projectText">{item.ToimitettuKplYht}</p>
                        </div>
                      </div>
                    </div>
                    {/*                <div className="projectArrow">
                      <RightOutlined className="arrow" style={{marginLeft:20}}/>
                    </div> */}
                  </div>

                </div>
                // <p
                //   className="projects-projectname font-itc"
                // onClick={() => {
                //   openProject(item.Tyokohdenro);
                // }}
                // >
                //   {item.Tyokohdenro+' '+item.Tyokohde}
                //   <RightOutlined style={{marginLeft:20}}/>
                // </p>
              );
            })
          : 
          <h1 className="noprojects font-dm-medium">{locale.noprojects}</h1>
          }
        </div>
      </div>
    </>
  );
};

/*
  VANGHA
            return (
              <div key={item.Tyokohdenro} className="homepage-card">

                <div className="homepage-card-inner"  onClick={() => {openProject(item.Tyokohdenro)}}>
                  <p className="homepage-card-header font-itc">{item.Nimi}</p>
                  <p className="homepage-card-projectid font-itc">{item.Tyokohdenro}</p>

                  <ItemValue
                    v1={0} 
                    v2={0} 
                    v3={0} 
                    v4={0} 
                  />
                </div>

              



              </div>

            );


*/
