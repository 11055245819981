import "./FactorySwitcher.css";
import { useDispatch, useSelector } from "react-redux";
import AllActions from "../../store/actions";
import { useTranslate } from "../Translate";

import { Dropdown, Menu, Space } from "antd";
import { useState, useEffect } from "react";
import { getEnvSettings } from "../../config/config";






export function FactoryMenu() {
  const { tehtaat, selectedFactory } = useSelector(
    (state) => state.currentUser.user
  );

  const envConfig = getEnvSettings();


  if (!envConfig.showFactorySel) return []


  let newArray = [];
  Object.keys(tehtaat).map((key) => {
    let factoryData = tehtaat[key];

    if (!factoryData.MobiiliKaytossa) return;

    newArray.push({
      label: (
        <p className="menuValue font-dm-medium" >
            {factoryData.name}
        </p>
      ),
      key: "factory_"+key,
    });
  });
  

  return ([{
      label: (
        <span className="displayValue font-dm-medium">
         {tehtaat[selectedFactory].name}
        </span>
      ),
      key: "factories",
      children: newArray
    }])
}



export function FactorySwitcher({ color, additionalClasses = "" }) {
  const dispatch = useDispatch();
  const { tehtaat, selectedFactory } = useSelector(
    (state) => state.currentUser.user
  );
  const [menuVisible, setMenuVisible] = useState(false);

  const { locale } = useTranslate();

  //console.log("tehtaat1", tehtaat);
  //console.log("selectedFactory", selectedFactory);

  const updateFactory = (id) => {
    console.log("updateFactory", id);
    const factoryNumber = id.replace("factory_", "");
    dispatch(AllActions.userActions.updateFactory(factoryNumber));
  };

  const onClick = ({ key }) => {
    console.log(`Click on item ${key}`);
    updateFactory(key);
    setMenuVisible(false);
  };


  return (
    <Dropdown
      className="lngDropdown"
      menu={{ items: FactoryMenu()[0].children, onClick }}
      trigger={["click"]}
      open={menuVisible}
      onOpenChange={(visible) => {
        setMenuVisible(visible);
      }} 
      placement="bottomRight"
    >
      <p className="displayValue font-dm-medium">
        {tehtaat[selectedFactory].name}
      </p>
    </Dropdown>

    /*     <div>
        <select className={"selectBtn "+additionalClasses} onChange={updateFactory} defaultValue={selectedFactory}>
           {Object.keys(tehtaat).map((key) => {
            let factoryData = tehtaat[key]

            if (!factoryData.MobiiliKaytossa) return

            return <option className="selectOption" key={key} value={key}>{factoryData.name}</option>
          })} 
        </select>
    </div> */
  );
}
