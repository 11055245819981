import axios from "axios";
import config from "../../config/config";
import userActions from "./userActions";

//Redux Store

const getProjectList = () => async (dispatch) => {
  console.log("getProjectList");

  const token = await userActions.getToken();

  let req_config = {
    method: "GET",
    url: `${config.api_url}/projects`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(req_config);
  const data = response.data.message;

  //console.log("PROJECTLISTRESPONSE:", data);

  dispatch({
    type: "UPDATE_PROJECTLIST",
    payload: data,
  });
};

export default {
  getProjectList,
};
