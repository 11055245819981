import languages from "../languages/languages";

import { useDispatch, useSelector } from "react-redux";


export const Trans = ({children}) => {
  const text = children
  const { lang } = useSelector((state) => state.currentUser.user);

  //console.log('text:', text)
  //console.log('translateLang:', lang)

  // return the translated text or the original text
  if (Object.keys(languages).includes(lang)) {
    return languages[lang][text] || 'Missing translation ('+text+')';
  }

  return 'LanguageNotFound';
};

export const useTranslate = () => {
  const { lang } = useSelector((state) => state.currentUser.user);

  let localeFound = false

  if (Object.keys(languages).includes(lang)) localeFound = true
  
  const locale = localeFound ? languages[lang] : languages['fi']

  return {
    locale
  };
};
