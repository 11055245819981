import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Schedule.css";

import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders
} from "react-calendar-timeline";

// make sure you include the timeline stylesheet or the timeline will not be styled
import moment from "moment";
import "moment/locale/fi";
import "react-calendar-timeline/lib/Timeline.css";
import {
  getParsedDate,
  getParsedDateShort
} from "../../components/globalFunctions";
import { AddWorkModal } from "./AddWorkModal/AddWorkModal";

import {
  DownOutlined,
  PlusCircleOutlined,
  UpOutlined
} from "@ant-design/icons";
import { drawLine, DrawTooltip } from "./ScheduleSharedFunc";

export const WorkersView = ({
  usersData,
  getUsersData,
  groupsOpen,
  setGroupsOpen,
  handleBtnOpenAll,
  btnAllOpen,
  part
}) => {
  const TODAY = moment();
  const DAY = 1000 * 60 * 60 * 24;
  const WEEK = DAY * 7;
  const MONTH = WEEK * 4;

  const MIN_ZOOM = WEEK * 3;
  const MAX_ZOOM = MONTH * 6;

  // const [isLoading, setisLoading] = useState(false);

  const [userWorks, setUserWorks] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [itemsData, setItemsData] = useState([]);

  const { selectedFactory } = useSelector((state) => state.currentUser.user);

  //Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});


  // const [groupsOpen, setGroupsOpen] = useState({});


  console.log('usersData', usersData)

  console.log('part', part)


  useEffect(() => {
    console.log("groupsOpen updated", groupsOpen);
  }, [groupsOpen]);




  //Tables to Timeline
  useEffect(() => {
    if (usersData.tyontekijasisalto || usersData.lomat) {
      let newuserWorks = [];
      let newGroups = [];
      let newItems = [];

      let datasisalto = usersData.tyontekijasisalto;
      let datalomat = usersData.lomat;
      let datapyhat = usersData.arkipyhat;
      let datamuut = usersData.muuttyontekijat;

      let dataasennustekijat = usersData.asennustekijat;
      let dataasennusrivit = usersData.asennusrivit;

      //Lajitellaan pyhät päivämäärän mukaan jotta rivit muodostuu oikein
      datapyhat.sort(function(a,b){
        return new Date(a.ArkipyhaPvm) - new Date(b.ArkipyhaPvm);
      });

      console.log("tyontekijasisalto", datasisalto);
      //console.log("lomat", datalomat);

      let lastLineEnd = {
        user: null,
        date: null,
      };

      newGroups.push({
        id: "workers",
        title: "Tuotanto",
        isOpenableProject: true,
        subtitle: true,
        //height: 80,
      });

      datasisalto.map((row, index) => {
  /*          if (row.TyontekijaNro != 478) return  */

        //Create group if not exists
        if (!newGroups.some((person) => person.id === row.TyontekijaNro)) {


          if (groupsOpen["workers"]) {
            newGroups.push({
              id: row.TyontekijaNro,
              title: row.TyontekijaNimi,
            });
          }


        }


        console.log('test12345 datasisalto',  row.TyontekijaNro, row.TyontekijaNimi)


        if (row.AikajananPituus) {
          //console.log(row.TyontekijaNimi, row.KirjausId, row.EdellinenTyo);

          ////console.log("lastLineEnd", lastLineEnd);

          let currDate =
            lastLineEnd.user == row.TyontekijaNro && lastLineEnd.date != null
              ? moment(lastLineEnd.date).add(1, "days")
              : moment();

          let lineStart = moment(currDate);
          let lineEnd = moment(currDate).add(row.AikajananPituus, "days");
          let lineLength = row.AikajananPituus;
          let lastVacationEnd = null;

          //Tarkistetaan lomat, jos löytyy stopataan viiva siihen ja jatketaan loman jälkeen
          datalomat.map((vacation, index) => {
            if (vacation.TyontekijaNro != row.TyontekijaNro) return;

            console.log('test12345 datalomat', vacation.TyontekijaNro)

            //Tarkistetaan alkaako loma samaanaikaan kuin kuormitus
            let sameStartDay =
              getParsedDate(vacation.AlkuPvm) == getParsedDate(lineStart);

            //Jos loman aloituspäivä on viivan välissä
            if (
              moment(vacation.AlkuPvm).isBetween(lineStart, lineEnd) ||
              sameStartDay
            ) {
              //Tarkistetaan tuleeko lomat peräkkäin
              let lastVEnd = getParsedDate(
                moment(lastVacationEnd).add(1, "days")
              );
              let currVStart = getParsedDate(moment(vacation.AlkuPvm));
              let inRow = lastVEnd == currVStart;

              if (!inRow && !sameStartDay) {
                //Lisätään viiva tähän mennessä

                drawLine(
                  newItems,
                  row.TyontekijaNro,
                  row.Tyokohde + " | " + row.ElementtiRyhma,
                  lineStart,
                  moment(vacation.AlkuPvm).add(-1, "days"),
                  "work",
                  {
                    workId: row.Tyokohdenro,
                    rowId: row.KirjausId,
                    data: {
                      name: row.Tyokohde,
                      id: row.Tyokohdenro,
                      elementgroup: row.ElementtiRyhma,
                      table: row.Poyta,
                      percent: row.KuormitusOsuus,
                      kpl: row.ValmistamattaKpl,
                      multiplier: row.HenkilokohtainenKerroin,
                      pvmReady: null,
                    },
                  }
                );

                //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
                let currLineLength = moment(vacation.AlkuPvm).diff(
                  moment(lineStart),
                  "days"
                );

                lineLength = lineLength - currLineLength;
              }

              //Piirretään loman viiva
              drawLine(
                newItems,
                row.TyontekijaNro,
                vacation.PoissaoloSyy,
                vacation.AlkuPvm,
                vacation.LoppuPvm,
                "vacation",
                {
                  vacationId: vacation.PoissaoloNro,
                  data: {
                    name: vacation.PoissaoloSyy,
                    startdate: getParsedDateShort(vacation.AlkuPvm),
                    enddate: getParsedDate(vacation.LoppuPvm),
                    durationD: vacation.KestoPv,
                    durationH: vacation.KestoH,
                  },
                }
              );

              //Päivitetään loman lopetuspäivä
              lastVacationEnd = moment(vacation.LoppuPvm);

              //Seuraavan aikajanapätkän alku on kyseisen poissaolomerkinnän LoppuPvm + 1.
              lineStart = moment(vacation.LoppuPvm).add(1, "days");

              //Seuraavan aikajanapätkän loppu on kyseisen poissaolomerkinnän LoppuPvm + aikajanapätkän jäljelle jäävä määrä
              lineEnd = moment(vacation.LoppuPvm).add(lineLength, "days");
            }
          });

      /*     datapyhat. */



          //Tarkistetaan lomat, jos löytyy stopataan viiva siihen ja jatketaan loman jälkeen
          datapyhat.map((day, index) => {


            console.log('test12345 datapyhat', day)


            //Tarkistetaan alkaako loma samaanaikaan kuin kuormitus
            let sameStartDay =
              getParsedDate(day.ArkipyhaPvm) == getParsedDate(lineStart);

            //Jos loman aloituspäivä on viivan välissä
            if (
              moment(day.ArkipyhaPvm).isBetween(lineStart, lineEnd) ||
              sameStartDay
            ) {
              //console.log("Loma osuu kuormitukselle");

              //Tarkistetaan tuleeko lomat peräkkäin
              let lastVEnd = getParsedDate(
                moment(lastVacationEnd).add(1, "days")
              );
              let currVStart = getParsedDate(moment(day.ArkipyhaPvm));
              let inRow = lastVEnd == currVStart;

              if (!inRow && !sameStartDay) {
                drawLine(
                  newItems,
                  row.TyontekijaNro,
                  row.Tyokohde + " | " + row.ElementtiRyhma,
                  lineStart,
                  moment(day.ArkipyhaPvm).add(-1, "days"),
                  "work",
                  {
                    workId: row.Tyokohdenro,
                    rowId: row.KirjausId,
                    data: {
                      name: row.Tyokohde,
                      id: row.Tyokohdenro,
                      elementgroup: row.ElementtiRyhma,
                      table: row.Poyta,
                      percent: row.KuormitusOsuus,
                      kpl: row.ValmistamattaKpl,
                      multiplier: row.HenkilokohtainenKerroin,
                      pvmReady: null,
                    },
                  }
                );

                //Vähennetään loppukuormituksen pituudesta jo piirretty pituus
                let currLineLength = moment(day.ArkipyhaPvm).diff(
                  moment(lineStart),
                  "days"
                );

                lineLength = lineLength - currLineLength;
              }

              //Pyhäpäivä viiva
              drawLine(
                newItems,
                row.TyontekijaNro,
                day.Selite,
                day.ArkipyhaPvm,
                day.ArkipyhaPvm,
                "holiday",
                {
                  holidayId: day.ArkipyhaId,
                  data: {
                    name: day.Selite,
                    date: getParsedDate(day.ArkipyhaPvm),
                  },
                }
              );

              //Päivitetään loman lopetuspäivä
              lastVacationEnd = moment(day.ArkipyhaPvm);

              //Seuraavan aikajanapätkän alku on kyseisen poissaolomerkinnän LoppuPvm + 1.
              lineStart = moment(day.ArkipyhaPvm).add(1, "days");

              //Seuraavan aikajanapätkän loppu on kyseisen poissaolomerkinnän LoppuPvm + aikajanapätkän jäljelle jäävä määrä
              lineEnd = moment(day.ArkipyhaPvm).add(lineLength, "days");
            }
          });

          //Jos lopetuspv on perjantai tai lauantai -> siirretään sunnuntaille.
          //console.log("LopetusPv", moment(lineEnd).day());
          let endDay = moment(lineEnd).day(); // 5 = perjantai,6 = lauantai
          if (endDay == 5) lineEnd = moment(lineEnd).add(2, "days");
          if (endDay == 6) lineEnd = moment(lineEnd).add(1, "days");

          //koko / loppupätkä
          drawLine(
            newItems,
            row.TyontekijaNro,
            row.Tyokohde + " | " + row.ElementtiRyhma,
            lineStart,
            lineEnd,
            "work",
            {
              workId: row.Tyokohdenro,
              rowId: row.KirjausId,
              data: {
                name: row.Tyokohde,
                id: row.Tyokohdenro,
                elementgroup: row.ElementtiRyhma,
                table: row.Poyta,
                percent: row.KuormitusOsuus,
                kpl: row.ValmistamattaKpl,
                multiplier: row.HenkilokohtainenKerroin,
                pvmReady: null,
              },
            }
          );

          //Päivitetään pätkän LoppuPvm
          lastLineEnd = {
            user: row.TyontekijaNro,
            date: lineEnd,
          };

          //Päivitetään arvioitu valmistuminen kaikkiin riveihin tooltippiä varten
          let shortEnd = getParsedDateShort(lineEnd);
          newItems.map((itemrow, index) => {
            if (itemrow.rowId == row.KirjausId) {
              //console.log('samaa työtä2:',itemrow )
              itemrow.data.pvmReady = shortEnd;
            }
          });

          //Tallennetaan työ tauluun. Käytetään modalistta "Edelliset työt"
          let userId = "user_" + row.TyontekijaNro;
          if (!newuserWorks[userId]) newuserWorks[userId] = [];

          newuserWorks[userId].push({
            KirjausId: row.KirjausId,
            workid: row.Tyokohdenro,
            name: row.Tyokohde,
            elementgroup: row.ElementtiRyhma,
            endDate: lineEnd,
          });
        }

        //Jos työsuhde päättynyt
        if (row.ResurssinPaattymisPvm) {
          /*console.log(
            "row.ResurssinPaattymisPvm",
            row,
            row.ResurssinPaattymisPvm
          );*/
          drawLine(
            newItems,
            row.TyontekijaNro,
            "Työsuhde päättynyt",
            moment(row.ResurssinPaattymisPvm).add(1, "days"),
            moment(row.ResurssinPaattymisPvm).add(1, "year"),
            "jobended"
          );
        }

        /*  }  */

        /*
                //Muut pyhät
                datapyhat.map((day, index) => {
                  if (day.ArkipyhaPvm) {
                    newItems.push({
                      id: newItems.length + 1,
                      group: row.TyontekijaNro,
                      title: day.Selite || "Syytä ei ilmoitettu",
                      vacation: false,
                      className: 'item-type-dayoff',
                      start_time: formatLineStart(day.ArkipyhaPvm),
                      end_time: formatLineEnd(day.ArkipyhaPvm),
                    });
                  }
                }); */
      });

      console.log("groupsOpen", groupsOpen);
      console.log("datamuut", datamuut);






      //
      //  ASENNUS
      //
      let groupInstall = "other_Asennus";


      if (dataasennustekijat.length >= 1) {

      newGroups.push({
        id: groupInstall,
        title: 'Asennus',
        isOpenableProject: true,
        subtitle: true,
        /* height: 80, */
      });
    }

      //Asennus
      dataasennustekijat.map((worker, index) => {
        console.log("asennustekijat", worker);
 
        //Tarkistetaan löytyykö jo
        if (!newGroups.some((project) => project.id === worker.TyontekijaNro)) {
          //console.log("muutrow create", group.RyhmaNimi);

          if (groupsOpen[groupInstall]) {
            newGroups.push({
              id: worker.TyontekijaNro,
              title: worker.TyontekijaNimi,
              installworker: true,
            });
          }

        }
      });


      dataasennusrivit.map((row, index) => {
        let haveProject = false

        if (row.Tyokohde) haveProject = true

        let lineStart = row.Alku
        let lineEnd = row.Loppu
        let lastVacationEnd = null;


        datapyhat.map((day, index) => {
          //Tarkistetaan alkaako loma samaanaikaan kuin kuormitus
          let sameStartDay =
            getParsedDate(day.ArkipyhaPvm) == getParsedDate(lineStart);

          //Jos loman aloituspäivä on viivan välissä
          if (
            moment(day.ArkipyhaPvm).isBetween(lineStart, lineEnd) ||
            sameStartDay
          ) {

            //Tarkistetaan tuleeko lomat peräkkäin
            let lastVEnd = getParsedDate(
              moment(lastVacationEnd).add(1, "days")
            );
            let currVStart = getParsedDate(moment(day.ArkipyhaPvm));
            let inRow = lastVEnd == currVStart;

            if (!inRow && !sameStartDay) {

              drawLine(
                newItems,
                row.Tyontekija,
                haveProject ? row.TyokohdeNimi + ' | ' +row.Sisalto : row.Sisalto,
                lineStart,
                moment(day.ArkipyhaPvm).add(-1, "days"),
                haveProject ? "install_proj" : 'install',
                {
                  data: {
                    name: (haveProject ? row.TyokohdeNimi : row.Sisalto),
                    text: (haveProject ? row.Sisalto : ''),
                    date: getParsedDate(row.Alku) + ' - '+ getParsedDate(row.Loppu),
                  }
                }
              );
            }

            //Pyhäpäivä viiva
            drawLine(
              newItems,
              row.Tyontekija,
              day.Selite,
              day.ArkipyhaPvm,
              day.ArkipyhaPvm,
              "holiday",
              {
                holidayId: day.ArkipyhaId,
                data: {
                  name: day.Selite,
                  date: getParsedDate(day.ArkipyhaPvm),
                },
              }
            );

            //Päivitetään loman lopetuspäivä
            lastVacationEnd = moment(day.ArkipyhaPvm);

            //Seuraavan aikajanapätkän alku on kyseisen poissaolomerkinnän LoppuPvm + 1.
            lineStart = moment(day.ArkipyhaPvm).add(1, "days");
          }
        });


        drawLine(
          newItems,
          row.Tyontekija,
          haveProject ? row.TyokohdeNimi + ' | ' +row.Sisalto : row.Sisalto,
          lineStart,
          lineEnd,
          haveProject ? "install_proj" : 'install',
          {
            data: {
              name: (haveProject ? row.TyokohdeNimi : row.Sisalto),
              text: (haveProject ? row.Sisalto : ''),
              date: getParsedDate(row.Alku) + ' - '+ getParsedDate(row.Loppu),
            }
          }
        );


      });

      //Luodaan muut työntekijät/groupit
      datamuut.map((group, index) => {
        if(group.RyhmaNimi) {
          let groupId = "other_" + group.RyhmaNimi;
  
          //console.log("muutrow", group.RyhmaNimi, group.TyontekijaNimi);
  
          //Tarkistetaan löytyykö jo
          if (!newGroups.some((project) => project.id === groupId)) {
            //console.log("muutrow create", group.RyhmaNimi);
  
            newGroups.push({
              id: groupId,
              title: group.RyhmaNimi,
              isOpenableProject: true,
              subtitle: true,
              /* height: 80, */
            });
          }
  
          if (groupsOpen[groupId]) {
            newGroups.push({
              id: group.TyontekijaNro,
              title: group.TyontekijaNimi,
              stockworker: true,
            });
          } else {
          }
        }
      });

      //Muut lomat
      datalomat.map((vacation, index) => {
        if (vacation.AlkuPvm && vacation.LoppuPvm) {
          if (
            newItems.some((item) => item.vacationId === vacation.PoissaoloNro)
          )
            return;

          console.log("muuloma", vacation);

          //Jos aloitusPvm vanhempi kuin nykyinen pvm, asetetaan nykyinen
          //let newStartDate = vacation.AlkuPvm;
          //if (moment(vacation.AlkuPvm) < moment()) newStartDate = moment();

          drawLine(
            newItems,
            vacation.TyontekijaNro,
            vacation.PoissaoloSyy,
            vacation.AlkuPvm,
            vacation.LoppuPvm,
            "vacation",
            {
              vacationId: vacation.PoissaoloNro,
              data: {
                name: vacation.PoissaoloSyy,
                startdate: getParsedDateShort(vacation.AlkuPvm),
                enddate: getParsedDate(vacation.LoppuPvm),
                durationD: vacation.KestoPv,
                durationH: vacation.KestoH,
              },
            }
          );
        }
      });

      //Muut pyhät
      newGroups.map((userGroup, index) => {
        console.log("userGroup", userGroup);

        //Tarkistetaan lomat, jos lomaa ei ole vielä piirretty > piirretään
        datapyhat.map((day, index) => {
          if (
            newItems.some(
              (item) =>
                (item.holidayId === day.ArkipyhaId &&
                  item.group === userGroup.id) ||
                userGroup.subtitle
            )
          )
            return;

          //Pyhäpäivä viiva
          drawLine(
            newItems,
            userGroup.id,
            day.Selite,
            day.ArkipyhaPvm,
            day.ArkipyhaPvm,
            "holiday",
            {
              holidayId: day.ArkipyhaId,
              data: {
                name: day.Selite,
                date: getParsedDate(day.ArkipyhaPvm),
              },
            }
          );
        });
      });

      //console.log("userWorks", newuserWorks);
      console.log("newGroups", newGroups);
      console.log("newItems", newItems);

      setUserWorks(newuserWorks);
      setGroupsData(newGroups);
      setItemsData(newItems);
    }
  }, [usersData, groupsOpen]);

  const groupRenderer = ({ group }) => {
    return (
      <>
        {group.subtitle ? (
          <div
            className={`subtitle-group`}
            onClick={() => {
              console.log('click', group)

              if (group.isOpenableProject) {
                let OpenList = groupsOpen;
                let IsOpen = OpenList[group.id];
                OpenList[group.id] = !IsOpen;

                //setGroupsData([]);

                //Odotetaan 200ms ennenkuin lasketaan/näytetään uudet viivat -> ei näyttö välähtele niin paljon
                //setTimeout(() => {
                  setGroupsOpen({
                    ...OpenList,
                  });
                //}, 200);
              }
            }}
          >
            <span className={`title font-itc`}>{group.title}</span>
          </div>
        ) : (
          <div className={`custom-group`}>
            <span className={`title font-dm-bold`}>{group.title}</span>

            {!group.no_add && (
              <PlusCircleOutlined
                className="add"
                onClick={() => {
                  let userworksArray = userWorks["user_" + group.id] || [];

                  let jobEnding = usersData.tyontekijasisalto.find(
                    (row) =>
                      row.TyontekijaNro === group.id &&
                      row.ResurssinPaattymisPvm != null
                  )?.ResurssinPaattymisPvm;

                  // let workData = usersData.tyontekijasisalto.find(
                  //   (row) => row.KirjausId === item.rowId
                  // );

                  console.log("testi:_", usersData.tyontekijasisalto)

                  ////console.log('jobEnding', jobEnding)

                  // if (group.stockworker)



                  setModalData({
                    userId: group.id,
                    userName: group.title,
                    userWorks: userworksArray,
                    jobEnding: jobEnding,
                    stockWorker: group.stockworker && true,
                    installWorker: group.installworker && true,
                  });
                  setModalOpen(true);
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };


  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    let itemProps = getItemProps({});

    let tooltipTypes = {
      work: true,
      vacation: true,
      holiday: true,
      install: true,
      install_proj: true,
    };

    let showTooltip = tooltipTypes[item.type];

    return (
      <>
        <div
          data-tip
          data-for={"tooltip-" + item.id}
          {...itemProps}
          onMouseDown={(e) => {
            if (e.detail == 1) {
              console.log("on item click", item);

            }

            if (e.detail == 2) {
              console.log("on item dobleclick", item);


              if (item.className == "item-type-vacation") {


                let vacationData = usersData.lomat.find(
                  (row) => row.PoissaoloNro === item.vacationId
                );

                let kayttaja = []

                kayttaja = usersData.muuttyontekijat.find(
                  (row) => row.TyontekijaNro === vacationData.TyontekijaNro
                )

                if (!kayttaja) {
                  kayttaja = usersData.tyontekijasisalto.find(
                    (row) => row.TyontekijaNro === vacationData.TyontekijaNro
                  )
                }

                console.log("vacation", vacationData)

                

                setModalOpen(true)
                setModalData({
                  alku: vacationData.AlkuPvm,
                  kestoH: vacationData.KestoH,
                  kestoPv: vacationData.kestoPv,
                  loppu: vacationData.LoppuPvm,
                  pekkanen: vacationData.Pekkanen,
                  poissaoloNro: vacationData.PoissaoloNro,
                  poissaoloSyy: vacationData.PoissaoloSyy,
                  poissaoloSyyNro: vacationData.PoissaoloSyyNro,
                  sairasloma: vacationData.Sairasloma,
                  tehdas: vacationData.Tehdas,
                  todellinenAlku: vacationData.TodellinenAlkuPvm,
                  tyoaikapankki: vacationData.TyoaikapankkiVapaa,
                  tyontekijaNro: vacationData.TyontekijaNro,
                  vuosiloma: vacationData.Vuosiloma,
                  username: kayttaja.TyontekijaNimi,
                  userId: kayttaja.TyontekijaNro
                })

                console.log("vacation", vacationData)
              
              } else {
                
                let workData = usersData.tyontekijasisalto.find(
                  (row) => row.KirjausId === item.rowId
                );
  
                let SelectedWork = usersData.tulevatuotanto.find(
                  (row) => row.ElementtiRyhmaNro === workData.ElementtiRyhmaNro
                );
  
                console.log("on workData dobleclick", workData);
                console.log("on SelectedWork dobleclick", SelectedWork);
  
                //Haetaan muut henkilön työt
                let userworksArray = (
                  userWorks["user_" + workData.TyontekijaNro] || []
                ).filter((workarr) => workarr.KirjausId != item.rowId);
  
                let jobEnding = usersData.tyontekijasisalto.find(
                  (row) => row.TyontekijaNro === workData.TyontekijaNro
                )?.ResurssinPaattymisPvm;

  
  
                setModalData({
                  editRow: true,
                  rowId: workData.KirjausId,
                  userId: workData.TyontekijaNro,
                  userName: workData.TyontekijaNimi,
                  userWorks: userworksArray,
                  jobEnding: jobEnding,
                  lastWork: workData.EdellinenTyo,
                  Tyokohdenro: workData.Tyokohdenro,
                  ElementtiRyhma: workData.ElementtiRyhma,
                  ElementtiRyhmaNro: workData.ElementtiRyhmaNro,
                  KuormittamattaKpl: workData.KuormittamattaKpl,
                  ValmistamattaKpl: workData.ValmistamattaKpl,
                  workTitle: workData.Tyokohde,
                  multiplier: workData.HenkilokohtainenKerroin,
                  percent: workData.KuormitusOsuus,
                  table: workData.PoytaNro,
                  reward: workData.ArvioTuotantopalkkioYht,
                  SelectedWork: SelectedWork,
                });
  
                setModalOpen(true);

              }

            }
          }}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
              paddingLeft: 5,
              paddingRight: 3,
              borderRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="font-dm-regular"
            >
              {itemContext.title}
            </p>
          </div>
        </div>

        {showTooltip && (
          <DrawTooltip
            id={"tooltip-" + item.id}
            type={item.type}
            data={item.data}
          />
        )}
      </>
    );
  };

  /*   if (isLoading) {
    return <Loading />;
  } */

  return (
    <>
      <AddWorkModal
        tables={usersData.poydat}
        elements={usersData.tulevatuotanto}
        installWorks={usersData.asennuskohteet}
        data={modalData}
        isOpen={modalOpen}
        closeModal={(refresh) => {
          setModalOpen(false);
          //console.log("refresh: ", refresh);
          if (refresh) getUsersData();
        }}
      />
      <div className="tl-container">
        {groupsData && groupsData.length > 0 && (
          <div id={`capture-henkilosto`}>
            <Timeline /* resizeDetector={containerResizeDetector} */
              groups={groupsData}
              items={itemsData}
              defaultTimeStart={moment().hours(0)}
              defaultTimeEnd={moment().hours(0).add(4, "week")}
              /*               visibleTimeStart={moment().hours(0)}
              visibleTimeEnd={moment().hours(0).add(1, "year")} */
              canMove={false}
              canResize={false}
              canChangeGroup={false}
           /*    stackItems */
              timeSteps={{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              sidebarWidth={220}
              lineHeight={50}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              /*               horizontalLineClassNamesForGroup={(group) => ["custom-group"]}
               */ minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              onItemSelect={() => {
                //console.log("item selected");
              }}
              onItemClick={null}
            >
              <TimelineHeaders>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      <div {...getRootProps()}>
                        {/* 
                        <p className="timeline-left-header font-itc">
                          Henkilöstö
                        </p> */}

                        {!btnAllOpen ? (
                          <UpOutlined
                            className="timeline-left-arrow"
                            onClick={() => {
                              handleBtnOpenAll();
                            }}
                          />
                        ) : (
                          <DownOutlined
                            className="timeline-left-arrow"
                            onClick={() => {
                              handleBtnOpenAll();
                            }}
                          />
                        )}

                        {/*                {item.open ? (
                        <UpOutlined className="arrow" />
                      ) : (
                        <DownOutlined className="arrow" />
                      )} */}
                      </div>
                    );
                  }}
                </SidebarHeader>
                <DateHeader unit="week" />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
          </div>
        )}
      </div>
      ,
    </>
  );
};
